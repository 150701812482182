import React from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import Modal from "react-modal";
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import AdminRoute from "../../Route/RouteDetails";
import plusIcon from "../../assets/images/icons/Icon feather-plus.png";

const MyReferrel = () => {
    const navigate = useNavigate();
  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
      </div>
      <div className="content">
        <Header isBack={true} header={"My Referrels"}></Header>
        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 20%)" }}
        >
          <div className="sub-head px-3" style={{ position: "relative" }}>
            <p className="sub-heading">Clients: 08</p>
            <p className="sub-lbl">Here is an overview of all your clients</p>
            <span>
              <img
                className="add-ref"
                src={plusIcon}
                onClick={() => navigate(`${AdminRoute?.Auth?.SelectReferral1}`)}
              />
            </span>
          </div>
          <div
            className="client-name-list"
            style={{ maxHeight: "calc(100% - 10%)" }}
          >
            <div className="client-name-card">
              <div>
                <div className="d-flex justify-content-between">
                  <label className="client-lbl1-ref">Meenakshi Narayan</label>
                  <label>10/12/2023 10:00 PM</label>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <label>Referring to:</label>
                </div>
                <div className="d-flex justify-content-between">
                  <label className="client-lbl1">advik.jain@gmail.com</label>
                  <label>9899789778</label>
                </div>
              </div>
            </div>
            <div className="client-name-card">
              <div>
                <div className="d-flex justify-content-between">
                  <label className="client-lbl1-ref">Meenakshi Narayan</label>
                  <label>10/12/2023 10:00 PM</label>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <label>Referring to:</label>
                </div>
                <div className="d-flex justify-content-between">
                  <label className="client-lbl1">advik.jain@gmail.com</label>
                  <label>9899789778</label>
                </div>
              </div>
            </div>
            <div className="client-name-card">
              <div>
                <div className="d-flex justify-content-between">
                  <label className="client-lbl1-ref">Meenakshi Narayan</label>
                  <label>10/12/2023 10:00 PM</label>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <label>Referring to:</label>
                </div>
                <div className="d-flex justify-content-between">
                  <label className="client-lbl1">advik.jain@gmail.com</label>
                  <label>9899789778</label>
                </div>
              </div>
            </div>
            <div className="client-name-card">
              <div>
                <div className="d-flex justify-content-between">
                  <label className="client-lbl1-ref">Meenakshi Narayan</label>
                  <label>10/12/2023 10:00 PM</label>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <label>Referring to:</label>
                </div>
                <div className="d-flex justify-content-between">
                  <label className="client-lbl1">advik.jain@gmail.com</label>
                  <label>9899789778</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyReferrel;
