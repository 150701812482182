import React, { useEffect, useState } from "react";
import AgoraUIKit, { layout } from "agora-react-uikit";
import "agora-react-uikit/dist/index.css";
import { useNavigate, useLocation } from "react-router-dom";

const VideoCall = () => {
    const navigate = useNavigate();
    const [videocall, setVideocall] = useState(false);
    const [isHost, setHost] = useState(true);
    const [isPinned, setPinned] = useState(false);
    const [username, setUsername] = useState("");
    const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const MeetingToken = atob(queryParams.get("id"));
    const MeetingName = queryParams.get("name");

    const HandleEndCall = () => {
        setVideocall(false);
        setTimeout(() => {
            navigate(`/`);
        }, 500);
    };

    useEffect(() => {
        setVideocall(true)
    }, [])


    return (
        <div style={styles.container} >
            <div className="agora-call" style={styles.videoContainer}>
                {videocall ? (
                    <>
                        <div style={styles.nav}>
                            {/*
            <p style={{ fontSize: 20, width: 200 }}>
                You're {isHost ? 'a host' : 'an audience'}
              </p>
              <p style={styles.btn} onClick={() => setHost(!isHost)}>
                Change Role
              </p>
              <p style={styles.btn} onClick={() => setPinned(!isPinned)}>
                Change Layout
              </p>
          */}
                        </div>
                        <AgoraUIKit
                            rtcProps={{
                                appId: "d1eb88599aae4650840d9d76dcf5b7f3",
                                channel: MeetingName,
                                token: MeetingToken,
                                role: isHost ? "host" : "audience",
                                layout: isPinned ? layout.pin : layout.grid,
                                enableScreensharing: false,
                            }}
                            rtmProps={{
                                username: UserData?.userdata?.fullname || "user",
                                displayUsername: true,
                            }}
                            callbacks={{
                                EndCall: () => HandleEndCall(),
                            }}
                        />
                    </>
                ) : (
                    <div style={styles.nav}>
                        <input
                            style={styles.input}
                            placeholder="nickname"
                            type="text"
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);
                            }}
                        />
                        <h3 style={styles.btn} onClick={() => setVideocall(true)}>
                            Start Call
                        </h3>
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        flex: 1,
        backgroundColor: "#007bff22",
    },
    heading: { textAlign: "center", marginBottom: 0 },
    videoContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    nav: { display: "flex", justifyContent: "space-around" },
    btn: {
        backgroundColor: "#007bff",
        cursor: "pointer",
        borderRadius: 5,
        padding: "4px 8px",
        color: "#ffffff",
        fontSize: 20,
    },
    input: { display: "flex", height: 24, alignSelf: "center" },
};

export default VideoCall;
