import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import { useNavigate } from "react-router-dom";
import API from "../../Api/Api";

const PersonalityTests = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [allResourceData, setAllResourceData] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [loadingList, setLoadingList] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getsuravayDetails();
  }, []);

  const getsuravayDetails = () => {
    setLoadingList(true);
    try {
      API.Survey.Surveylist({
        title: "personality",
      }).then((response) => {
        if (response.data.status === 1) {
          setAllResourceData(response?.data?.data_all[0]);
          const trueAnswerIdAndValue = {};
          response?.data?.data_all[0].subsurvay?.forEach((question) => {
            const trueAnswer = question.answers.find(
              (answer) => Object.values(answer)[0] === true
            );
            if (trueAnswer) {
              trueAnswerIdAndValue[question._id] = Object.keys(trueAnswer)[0];
            }
          });
          setSelectedAnswers(trueAnswerIdAndValue);
        }
        setLoadingList(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingList(false);
    }
  };

  const handleAnswer = (
    e,
    ele,
    eleIndex,
    questionIndex,
    questionId,
    answerText
  ) => {
    //obj for comparing
    if (e.target.checked) {
      setSelectedAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: answerText,
      }));
    } else {
      setSelectedAnswers(delete selectedAnswers[questionId]);
    }

    // get selected key
    let key1 = Object.keys(
      allResourceData.subsurvay[eleIndex].answers[questionIndex]
    )[0];
    setAllResourceData({
      ...allResourceData, // Copy the original object
      subsurvay: allResourceData.subsurvay.map((question, index) => {
        if (eleIndex === index) {
          return {
            ...question, // Copy the original question object
            answers: question.answers.map((answer, i) => {
              const key = Object.keys(answer)[0];
              answer[key] = false; // set all false
              // Check a condition before updating the specific property
              if (i === questionIndex) {
                return {
                  ...answer,
                  [key1]: e.target.checked, // Update to the desired value
                };
              } else {
                return answer; // if not change return all same
              }
            }),
            created_by: UserData?.userdata?._id,
          };
        }
        return question;
      }),
    });
  };

  const handleSave = () => {
    // Check if all checkboxes are selected
    const areAllCheckboxesSelected =
      Object.keys(selectedAnswers).length === allResourceData.subsurvay.length;

    if (!areAllCheckboxesSelected) {
      // Show an alert or take any other action to inform the user that all checkboxes must be selected
      setError("Please select an answer for each question before submitting.");
      return;
    } else {
      setError("");
    }

    const newData = allResourceData.subsurvay.map(({ _id, ...rest }) => rest);
    API.Survey.SubmitSurvay({
      data: {
        data: newData,
        isupdate: allResourceData?.isupdate,
        survayType: "personality",
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          // Handle successful submission
          handleShow();
          setError("");
          setTimeout(() => {
            handleClose();
            navigate(`..${AdminRoute.Auth.Profile}`);
          }, 2000);
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img alt="logo" className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header
          isBack={true}
          header={"Personality Tests"}
          isShowIcons={false}
        ></Header>
        <MobMenuBar></MobMenuBar>
        <div
          className="dashboard-container analysis-form-v-height"
          style={{ maxHeight: "calc(100% - 160px)" }}
        >
          {/* ===========Wellness Tracker============ */}

          <div className="row px-3 mt-3 need-analysis-form">
            <div className="col-12 mb-2">
              <div className="d-flex justify-content-between">
                <p>Here is an overview of your session.</p>
              </div>
            </div>
            {loadingList ? null : allResourceData.length === 0 ? (
              <div className="alert alert-warning" role="alert">
                Data Not Found
              </div>
            ) : (
              allResourceData &&
              allResourceData?.subsurvay?.map((ele, index) => {
                return (
                  <div className="col-md-12 col-lg-4 mb-3">
                    <div className="dashboard-inner p-2 h-100">
                      <div className="dashboard-text-div h-100">
                        <p className="px-3 pb-2">
                          {index + 1}. in general, which statement describes you
                          best?
                        </p>

                        <div className="analysis-form p-3 h-100">
                          <p className="sessions-summery-text">
                            {ele.answers.map((val, i) => {
                              const isChecked =
                                selectedAnswers[ele._id] ===
                                Object.keys(val)[0];
                              return (
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-check-input"
                                    id="flexCheckDefault"
                                    name={Object.keys(val)[0]}
                                    checked={isChecked}
                                    onChange={(e) =>
                                      handleAnswer(
                                        e,
                                        ele,
                                        index,
                                        i,
                                        ele._id,
                                        Object.keys(val)[0]
                                      )
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck1"
                                  >
                                    {Object.keys(val)[0]}
                                  </label>
                                </div>
                              );
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <p className="text-danger">{error}</p>
          <div className="row px-3 mt-3 justify-content-end">
            <div className="col-12 col-md-2">
              <Button
                className="button_common m-0 px-3 w-100"
                onClick={() => handleSave()}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalityTests;
