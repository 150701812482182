import React from "react";
import userImage from '../../assets/images/icons/Path 15093.png';
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from '../Common/SideBarMenu';
import Header from '../Common/Header';
import { Line, Radar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const data = {
    labels: [
        '',
        '',
        '',
        '',
        '',
    ],
    datasets: [{
        label: '',
        data: [65, 59, 90, 81, 56],
        fill: true,
        backgroundColor: 'rgb(127,154,158, 75%)',
        borderColor: 'rgb(255, 99, 132, 0%)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: 'rgb(255, 99, 132, 0%)',
        pointHoverBackgroundColor: 'rgb(255, 99, 132, 0%)',
        pointHoverBorderColor: 'rgb(255, 99, 132, 0%)',
        pointRadius: 0
    }, {
        label: '',
        data: [28, 48, 40, 19, 96],
        fill: true,
        backgroundColor: 'rgb(127,154,158, 75%)',
        borderColor: 'rgb(54, 162, 235, 0%)',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: 'rgb(255, 99, 132, 0%)',
        pointHoverBackgroundColor: 'rgb(255, 99, 132, 0%)',
        pointHoverBorderColor: 'rgb(54, 162, 235, 0%)',
        pointRadius: 0
    }]
};

const traingelData = {
    labels: [
        '',
        '',
        '',
    ],
    datasets: [{
        label: '',
        data: [65, 59, 90],
        fill: true,
        backgroundColor: 'rgb(127,154,158, 75%)',
        borderColor: 'rgb(255, 99, 132, 0%)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: 'rgb(255, 99, 132, 0%)',
        pointHoverBackgroundColor: 'rgb(255, 99, 132, 0%)',
        pointHoverBorderColor: 'rgb(255, 99, 132, 0%)',
        pointRadius: 0
    }, {
        label: '',
        data: [28, 48, 40],
        fill: true,
        backgroundColor: 'rgb(127,154,158, 0%)',
        borderColor: 'rgb(54, 162, 235, 0%)',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: 'rgb(255, 99, 132, 0%)',
        pointHoverBackgroundColor: 'rgb(255, 99, 132, 0%)',
        pointHoverBorderColor: 'rgb(54, 162, 235, 0%)',
        pointRadius: 0
    }]
};

const line1 = {
    labels: ['Jan','Feb','Mar','Apr','May', 'Jun', 'July', 'Aug', 'Nov', 'Dec'],
    datasets: [{
      label: '',
      data: [65, 59, 80, 81, 56, 55, 40,65, 59, 80, 81, 56, 55, 40 ],
      fill: false,
      borderColor: 'rgb(209,109,57)',
      borderWidth: 5,
      tension: 0.1
    }, 
    {
        label: '',
        data: [10, 55, 40, 65, 59, 80,65, 59, 80, 81,  81, 56, 55, 40 ],
        fill: false,
        borderColor: 'rgb(19,80,73)',
        borderWidth: 5,
        tension: 0.1
      }]
  };

  const lineOptions = {
    plugins: {
        legend: {
            display: false
        },
    },
  }

const config = {
    plugins: {
        legend: {
            display: false
        },
    },
    scales: {
        r: {
            pointLabels: {
                display: false // Hides the labels around the radar chart
            },
            ticks: {
                display: false // Hides the labels in the middel (numbers)
            }
        }
    },
    elements: {
        line: {
            borderWidth: 2
        }
    }
};



const ClientViewDet = () => {
    return (
        <div className="app-container">
            <div className="side-menu-panel">
                <div className="menu-panel-logo">
                    <img className="logo" src={rtylifeLogo} />
                </div>
                <div>
                    <SideBarMenu></SideBarMenu>
                </div>
            </div>
            <div className="content">
                <Header></Header>
                <div className="dashboard-container" style={{ maxHeight: '100%' }}>
                    <div className="sub-head px-3 d-flex">
                        <img style={{ height: '40px', marginRight: '10px' }} src={userImage} />
                        <div>
                            <p className="sub-heading"><strong>Akshay Mahajan</strong></p>
                            <p className="sub-lbl">Here are all the details of your client</p>
                        </div>
                    </div>
                    <div className="mb-2 client-view-cont">
                        <p>
                            First Name: Akshay
                        </p>
                        <p>
                            Last Name: Mahajan
                        </p>
                        <p>
                            Date of birth: 17 Nov 1992
                        </p>
                        <p>
                            email: akmahajan122@gmail.com
                        </p>

                        <p>
                            Mobile No.: +91 98798778999
                        </p>

                        <div className="client-name-card " style={{ background: 'rgb(238 226 209)' }}>
                            <div style={{ background: '#FFF9F1' }}>
                                <Radar data={data} options={config} />

                            </div>
                            <p className="px-2 my-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>Personality Test</p>
                            <p className="px-2 m-0">Published Date: 01 Sept, 2023</p>

                        </div>

                        <div className="client-name-card " style={{ background: 'rgb(238 226 209)' }}>
                            <div style={{ background: '#FFF9F1' }}>
                                <Radar data={traingelData} options={config} />

                            </div>
                            <p className="px-2 my-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>Personality Test</p>
                            <p className="px-2 m-0">Published Date: 01 Sept, 2023</p>

                        </div>

                        <div className="client-name-card " style={{ background: 'rgb(238 226 209)' }}>
                            <div style={{ background: '#FFF9F1' }}>
                                <Line data={line1} options={lineOptions} />

                            </div>
                            <p className="px-2 my-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>Personality Test</p>
                            <p className="px-2 m-0">Published Date: 01 Sept, 2023</p>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ClientViewDet;