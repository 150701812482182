import React, { useState } from "react";
import MobMenuBar from "../Common/MobMenuBar";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";

const FollowUpList = () => {
    const [isChecked, setIschecked] = useState(false);
    return (
        <div className="app-container">
            <div className="side-menu-panel">
                <div className="menu-panel-logo">
                    <img className="logo" src={rtylifeLogo} />
                </div>
                <div>
                    <SideBarMenu></SideBarMenu>
                </div>
            </div>
            <div className="content">
                <Header header={'Follow Ups'} isBack={true}></Header>
                <MobMenuBar></MobMenuBar>
                <div className="dashboard-container">
                    <div className="client-name-list" style={{ maxHeight: 'calc(100%)'}}>
                        <div className="d-flex justify-content-center">
                            <label className="day-lbl2">Today</label>
                        </div>
                        <div onClick={() => setIschecked(!isChecked)} className="client-name-card d-flex flex-row" style={{ background: '#FFFFFF' }}>
                            <div className="vert-div">

                            </div>
                            <div className="content-par mx-2">
                                <p className="m-0 follow-up-header">
                                    Create Activity
                                </p>
                                <p className="follow-up-subhead" style={{
                                    textDecoration: isChecked ? 'line-through' : 'auto'
                                }}>
                                    Here is an overview of your letest activities
                                </p>
                                <p className="my-2" style={{
                                    position: 'relative'
                                }}>
                                    Today 2:00PM
                                    {
                                        isChecked ?
                                            <>
                                                <span>
                                                    <label className="check_container">
                                                        <input
                                                            type="checkbox"
                                                            checked={true}
                                                        />
                                                        <span className="checkmark" style={{ top: '17px' }}></span>
                                                    </label>
                                                </span>
                                            </>
                                            : <></>
                                    }

                                </p>
                            </div>

                        </div>

                        <div onClick={() => setIschecked(!isChecked)} className="client-name-card d-flex flex-row" style={{ background: '#FFFFFF' }}>
                            <div className="vert-div">

                            </div>
                            <div className="content-par mx-2">
                                <p className="m-0 follow-up-header">
                                    Create Activity
                                </p>
                                <p className="follow-up-subhead" style={{
                                    textDecoration: isChecked ? 'line-through' : 'auto'
                                }}>
                                    Here is an overview of your letest activities
                                </p>
                                <p className="my-2" style={{
                                    position: 'relative'
                                }}>
                                    Today 2:00PM
                                    {
                                        isChecked ?
                                            <>
                                                <span>
                                                    <label className="check_container">
                                                        <input
                                                            type="checkbox"
                                                            checked={true}
                                                        />
                                                        <span className="checkmark" style={{ top: '17px' }}></span>
                                                    </label>
                                                </span>
                                            </>
                                            : <></>
                                    }

                                </p>
                            </div>

                        </div>

                        <div onClick={() => setIschecked(!isChecked)} className="client-name-card d-flex flex-row" style={{ background: '#FFFFFF' }}>
                            <div className="vert-div">

                            </div>
                            <div className="content-par mx-2">
                                <p className="m-0 follow-up-header">
                                    Create Activity
                                </p>
                                <p className="follow-up-subhead" style={{
                                    textDecoration: isChecked ? 'line-through' : 'auto'
                                }}>
                                    Here is an overview of your letest activities
                                </p>
                                <p className="my-2" style={{
                                    position: 'relative'
                                }}>
                                    Today 2:00PM
                                    {
                                        isChecked ?
                                            <>
                                                <span>
                                                    <label className="check_container">
                                                        <input
                                                            type="checkbox"
                                                            checked={true}
                                                        />
                                                        <span className="checkmark" style={{ top: '17px' }}></span>
                                                    </label>
                                                </span>
                                            </>
                                            : <></>
                                    }

                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FollowUpList;