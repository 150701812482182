import React, { useState, useEffect } from "react";
import MobMenuBar from "../Common/MobMenuBar";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

const ClientList = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [ClientActiveList, setClientActiveList] = useState([]);
  const [ClientInActiveList, setClientInActiveList] = useState([]);
  const [UpcommingList, setUpcommingList] = useState([]);
  const [activeIndex, setIndex] = useState(0);
  const [upcommingClientCount, setupcommingClientCount] = useState(0);
  const handleTabs = (e) => {
    setIndex(e);
  };

  const ClientActiveListfun = () => {
    try {
      API.Client.ClientActiveList({}).then((response) => {
        console.log("ClientActiveList", response?.data);
        setClientActiveList(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const ClientInActiveListfun = () => {
    try {
      API.Client.ClientInActiveList({}).then((response) => {
        // console.log("ClientInActiveList", response?.data);
        setClientInActiveList(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const upcomingListfun = () => {
    try {
      API.Client.UpcomingList({
        UserId: UserData?.userdata?._id,
        isApproved: "0",
      }).then((response) => {
        console.log("UpcommingList", response?.data?.data_all);
        setUpcommingList(response?.data?.data_all);
        setupcommingClientCount(response?.data?.data_all?.length);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ClientActiveListfun();
    ClientInActiveListfun();
    upcomingListfun();
  }, []);

  // Approve API==

  const handleAprroveClient = (e, clientIdd) => {
    e.preventDefault();
    // alert(clientIdd);
    try {
      API.Client.ApproveClient({
        data: {
          is_approve: 1,
        },
        clientId: clientIdd,
      }).then((response) => {
        console.log("Approve", response?.data);
        if (response?.data?.status === 1) {
          toast.success("User Approved");
          upcomingListfun();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
        <MobMenuBar></MobMenuBar>
        <div className="dashboard-container">
          <div className="sub-head px-3">
            <p className="sub-heading">Your Assigned Clients: 08</p>
            <p className="sub-lbl">Here is an overview of youe clients</p>
          </div>
          <div className="common-tabs">
            <div
              className="common-tab-item cursor-pointer"
              onClick={() => handleTabs(0)}
              style={
                activeIndex == 0
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Active
            </div>
            <div
              className="common-tab-item cursor-pointer"
              onClick={() => handleTabs(1)}
              style={
                activeIndex == 1
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Approval Waiting{" "}
              <span>
                <label className="common-count mx-2">
                  {upcommingClientCount}
                </label>
              </span>
            </div>
            <div
              className="common-tab-item cursor-pointer"
              onClick={() => handleTabs(2)}
              style={
                activeIndex == 2
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Inactive
            </div>
          </div>
          {activeIndex === 0 ? (
            <>
              <div className="client-name-list">
                {!ClientActiveList?.length ? (
                  <h3>Data not Available</h3>
                ) : (
                  ClientActiveList?.length &&
                  ClientActiveList?.map((val, i) => {
                    return (
                      // <NavLink to={`/client-details`}>
                      <div className="client-name-card" key={i}>
                        <div
                          onClick={() =>
                            navigate(
                              `${AdminRoute?.Auth?.ClientDetails}/${val?.client[0]?._id}`
                            )
                          }
                        >
                          <p className="m-0 client-lbl1 ">Client</p>
                          <p className="client-lbl2 ">{`${val?.client[0]?.name} ${val?.client[0]?.last_name}`}</p>
                        </div>
                        {/* <div className="client-lbl3">
                          <label className="red-dot"></label> New notes/
                          Activity
                        </div> */}
                        <img src={rightArrow} className="right-arr" />
                        {/* <label className="day-lbl">Today</label> */}
                      </div>
                      // </NavLink>
                    );
                  })
                )}
              </div>
            </>
          ) : activeIndex === 1 ? (
            <>
              {!UpcommingList?.length ? (
                <>
                  <br></br>
                  <h3>No pending approvals.</h3>
                </>
              ) : (
                UpcommingList?.length &&
                UpcommingList?.map((val, i) => {
                  return (
                    <div className="client-name-list" key={i}>
                      <div className="client-name-card align-items-center">
                        <div className="inner-card">
                          <p className="client-lbl2 m-0">{`${val?.client[0]?.name} ${val?.client[0]?.last_name}`}</p>
                          <div className="d-flex justify-content-between">
                            <label>Client</label>
                            <label>{moment(val?.updatedAt).format("ll")}</label>
                          </div>
                        </div>
                        <div
                          style={{ width: "90%" }}
                          className="d-flex justify-content-between mt-2"
                        >
                          <div className="det-div">
                            <p className="m-0 client-lbl1">Date:</p>
                            <p className="m-0 client-lbl1">
                              {/* <strong>02 August,23</strong> */}
                              <strong>{moment(val?.date).format("ll")}</strong>
                            </p>
                          </div>
                          <div className="det-div">
                            <p className="m-0 client-lbl1">Time:</p>
                            <p className="m-0 client-lbl1">
                              <strong>{val?.timestart}</strong>
                            </p>
                          </div>
                        </div>

                        <div
                          style={{ width: "90%" }}
                          className="d-flex justify-content-around mt-2"
                        >
                          <button
                            className="bottom-btns ref-btn"
                            onClick={() =>
                              navigate(`${AdminRoute?.Auth?.SelectReferral1}`)
                            }
                          >
                            Refer
                          </button>
                          <button
                            className="bottom-btns appr-btn "
                            onClick={(e) => handleAprroveClient(e, val?._id)}
                          >
                            Approve
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </>
          ) : (
            <>
              <div className="client-name-list">
                {!ClientInActiveList?.length ? (
                  <h3>No Inactive client</h3>
                ) : (
                  ClientInActiveList?.length &&
                  ClientInActiveList?.map((val, i) => {
                    return (
                      <div className="client-name-card" key={i}>
                        <div>
                          <p className="m-0 client-lbl1 ">Client</p>
                          <p className="client-lbl2 ">{`${val?.client[0]?.name} ${val?.client[0]?.last_name}`}</p>
                        </div>
                        <div className="client-lbl3">
                          <label className="red-dot"></label> New notes/
                          Activity
                        </div>
                        <img src={rightArrow} className="right-arr" />
                        <label className="day-lbl">Today</label>
                      </div>
                    );
                  })
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientList;
