import React from "react";
import userImage from "../../assets/images/icons/mentalhealthart-01.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import MobMenuBar from "../Common/MobMenuBar";

const AddEditSessionDetails = () => {
    return (
        <div className="app-container">
            <div className="side-menu-panel">
                <div className="menu-panel-logo">
                    <img className="logo" src={rtylifeLogo} />
                </div>
                <div>
                    <SideBarMenu></SideBarMenu>
                </div>
            </div>
            <div className="content">
                <Header header={'Edit Session Detials'}></Header>

                <MobMenuBar></MobMenuBar>
                <div className="dashboard-container">
                    <div className="sub-head px-3 d-flex">
                        <img
                            style={{ height: "40px", marginRight: "10px" }}
                            src={userImage}
                        />
                        <div>
                            <p className="sub-heading">
                                {/* <strong>31 July 2023 11:00 AM</strong> */}
                                <strong>
                                    31 July 2023 11:00 AM
                                </strong>
                            </p>
                            <p className="sub-lbl">Here are all your session details</p>
                        </div>
                    </div>
                    <div className="mb-2 client-view-cont">
                        <p className="my-2" style={{ color: "#32665F" }}>
                            <strong>Session Summary:</strong>
                        </p>
                        <textarea className="session-inp-text" rows={5}></textarea>
                        <p className="my-2" style={{ color: "#32665F" }}>
                            <strong>Observations:</strong>
                        </p>
                        <textarea className="session-inp-text" rows={5}></textarea>

                        <p className="my-2" style={{ color: "#32665F" }}>
                            <strong>Interventions:</strong>
                        </p>
                        <textarea className="session-inp-text" rows={5}></textarea>
                    </div>
                    <div className="d-flex justify-content-around">
                        <button className="session-btns sv-inp-btn">Save as In Progress</button>
                        <button className="session-btns sv-btn">Publish</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEditSessionDetails;