import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import AdminRoute from "./../../Route/RouteDetails";
import API from "../../Api/Api";
import GoogleLogin from "./../Firebase/firebase";

const Login = () => {
  const navigate = useNavigate();
  const initialValues = { InputType: "" };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const newUSer = queryParams.get("isnew");

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const mobileRegex = /^\d+$/;

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};

    if (!values?.InputType) {
      errors.InputType = "Email or Mobile no is Required!";
    }
    if (mobileRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Mobile Number!";
    }
    if (emailRegex.test(!values?.InputType)) {
      errors.InputType = "Enter Valid Email ID!";
    }
    if (
      mobileRegex.test(values?.InputType) &&
      emailRegex.test(values?.InputType)
    ) {
      errors.InputType = "Invalid Input!";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (emailRegex.test(formValues?.InputType)) {
        if (newUSer) {
          NewuserRegister();
        } else {
          try {
            setLoading(true);
            API.Auth.LoginAPIEmail({
              data: {
                email: formValues?.InputType,
                role: "Cmsemployee",
              },
            }).then((response) => {
              if (response?.data?.status === 1) {
                localStorage.setItem("EmailId", formValues?.InputType);
                // toast.success(response?.data?.message);
                setError("");
                navigate(
                  `../${AdminRoute?.Auth?.verifyOtpMail
                  }/1?email=${JSON.stringify(formValues?.InputType)}`
                );
              } else if (response?.data?.status === 0) {
                localStorage.setItem("EmailId", formValues?.InputType);
                // toast.error(response?.data?.message);
                setError(response?.data?.message);
                // navigate(
                //   `../${AdminRoute?.Auth?.Registration1}?email=${JSON.stringify(
                //     formValues?.InputType
                //   )}`
                // );
              }
              setLoading(false);
            });
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
        }
      }

      if (mobileRegex.test(formValues?.InputType)) {
        if (newUSer) {
          NewuserRegisterPhone();
        } else {
          try {
            setLoading(true);
            API.Auth.LoginAPIMobile({
              data: {
                phone: formValues?.InputType,
                role: "Cmsemployee",
              },
            }).then((response) => {
              console.log(response?.data);
              if (response?.data?.status === 1) {
                localStorage.setItem("Mobileno", formValues?.InputType);
                // toast.success(response?.data?.message);
                setError("");
                navigate(
                  `../${AdminRoute?.Auth?.VerifyOtp
                  }/1?mobile_no=${JSON.stringify(formValues?.InputType)}`
                );
              } else if (response?.data?.status === 0) {
                localStorage.setItem("Mobileno", formValues?.InputType);
                setError(response?.data?.message);
                // navigate(
                //   `../${
                //     AdminRoute?.Auth?.Registration1
                //   }?mobile_no=${JSON.stringify(formValues?.InputType)}`
                // );
              }
              setLoading(false);
            });
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
        }
      }
    }
  }, [formErrors]);

  const handleSubmitMobile = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const NewuserRegister = () => {
    if (newUSer) {
      API.Auth.SendmsOtpEmail({
        data: {
          email: formValues?.InputType,
          role: "Cmsemployee",
          isnew: true,
        },
      })
        .then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("EmailId", formValues?.InputType);
            setError("");
            navigate(
              `../${AdminRoute?.Auth?.verifyOtpMail}/1?email=${JSON.stringify(
                formValues?.InputType
              )}&newUSer=true`
            );
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
          console.log("try after some time", error);
        });
    } else {
      setIsSubmit(true);
    }
  };

  const NewuserRegisterPhone = () => {
    if (newUSer) {
      API.Auth.SendmsOtpEmail({
        data: {
          phone: formValues?.InputType,
          role: "Cmsemployee",
          isnew: true,
        },
      })
        .then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("EmailId", formValues?.InputType);
            setError("");
            navigate(
              `../${AdminRoute?.Auth?.VerifyOtp}/1?mobile_no=${JSON.stringify(
                formValues?.InputType
              )}&newUSer=true`
            );
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
          console.log("try after some time", error);
        });
    } else {
      setIsSubmit(true);
    }
  };

  return (
    <div className="land-container">
      <div className="logo-container">
        <img className="logo" alt="logo" src={rtylifeLogo} />
      </div>
      <div className="login-container">
        <div className="register-therapist-div register-therapist">
          <form>
            <h2 className="heading fw-600 py-2">Login</h2>

            <div>
              <label className="pb-2">
                * Enter registered E-Mail ID/ Mobile No.
              </label>
              <input
                type="text"
                name="InputType"
                placeholder="Enter your E-Mail ID/Mobile No."
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <p></p>
              <p className="text-danger">{formErrors?.InputType}</p>
            </div>
            {error && <p className="text-danger">{error}</p>}
            <button
              type="submit"
              className="button_common w-100 my-3 mb-3 text-center"
              onClick={(e) => handleSubmitMobile(e)}
            >
              Verify via OTP
            </button>
            {loading ? <div className="loader_orange"></div> : <></>}

            <div className="form-group">
              {/*
              <div className="custom-control custom-checkbox d-flex align-item-center ">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
              />
              <label
                className="custom-control-label ps-2"
                htmlFor="customCheck1"
              >
                Stay logged in
              </label>
            </div>
            */}
            </div>
            <h6 className="or-text text-center"></h6>
            {/* <div className="cursor-pointer">
              <GoogleLogin />
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
