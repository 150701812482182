import React from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import Modal from "react-modal";
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import AdminRoute from "../../Route/RouteDetails";
import logoutArrow from "../../assets/images/icons/back-arrow.png";

const Settings = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const naviagate = useNavigate();

  const LogoutFun = () => {
    localStorage.removeItem("cmsUserData");
    localStorage.removeItem("AuthTokenTherapist");
    localStorage.removeItem("Mobileno");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("RegUserData");
    naviagate(`../${AdminRoute?.Auth?.Login}`);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height: "200px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#FFF",
      borderRadius: "20px",
      paddingTop: "70px",
      width: "90%",
      maxWidth: "500px",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
      </div>
      <div className="content">
        <Header isBack={true} header={"Settings"}></Header>
        <div className="desc-container w-100" style={{ padding: 0 }}>
          <div
            className="settings-menu"
            onClick={() => naviagate(`../${AdminRoute?.Auth?.MyCalender}/2`)}
          >
            My Calender
            <img
              style={{ top: "18px" }}
              src={rightArrow}
              className="right-arr"
            />
          </div>
          <div className="settings-menu" onClick={() => openModal()}>
            Edit No. of Max Clients
            <img
              src={rightArrow}
              style={{ top: "18px" }}
              className="right-arr"
            />
          </div>
          <div
            className="settings-menu"
            onClick={() => naviagate(`../${AdminRoute?.Auth?.MyReferrel}`)}
          >
            My Referrels
            <img
              src={rightArrow}
              style={{ top: "18px" }}
              className="right-arr"
            />
          </div>
        </div>
      </div>

      <button className="log-btn" onClick={(e) => LogoutFun(e)}>
        <img src={logoutArrow} style={{ height: "20px" }} className="me-2" />
        Logout
      </button>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button
          onClick={closeModal}
          className="close-btn "
          style={{ top: "7%", zIndex: "100" }}
        >
          <img src={closeBtn} />{" "}
        </button>
        <p className="pop-up-head-containter">Max Clients</p>
        <p className="my-3" style={{ fontSize: "14px" }}>
          Edit no. of max clients
        </p>
        <input
          type="text"
          placeholder="Contact No."
          className="mb-3"
          name="Title"
          style={{ background: "#EFF2F2" }}
        />
      </Modal>
    </div>
  );
};

export default Settings;
