import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userImg from "../../assets/images/icons/userIMG.jpg";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import baseApi from "../../Api/config";
import { toast } from "react-toastify";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import plusIcon from "../../assets/images/icons/Icon feather-plus.png";

const ProfileEdit = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [UserInfo, setUserInfo] = useState({});

  useEffect(() => {
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          setUserInfo(response?.data?.data);
          setProfileImage(response?.data?.data?.image);
          setFormData({
            ...formData,
            Name: response?.data?.data.fullname,
            Last: response?.data?.data.last_name,
            DOB: response?.data?.data.dob,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const initialValues = {
    Name: "",
    Last: "",
    DOB: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleUpload = (e) => {
    console.log("calll");
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const RegisterValidate = (values) => {
    const errors = {};

    if (!values?.Name) {
      errors.Name = "Full Name is required";
    }
    // if (!values?.Last) {
    //   errors.Last = "Last Name is required";
    // }
    if (!values?.DOB) {
      errors.DOB = "Quote is required";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        API.Profile.UpdateProfileAPI({
          data: {
            fullname: formData.Name,
            last_name: formData.Last,
            dob: formData.DOB,
            image: ProfileImage,
          },
          UserId: UserInfo?._id,
        }).then((response) => {
          if (response.data.status === 1) {
            navigate(`../${AdminRoute?.Auth?.Dashboard}`);
          }
          // toast.success("update successfull");
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content profile-edit">
        <Header></Header>

        <div className="desc-container">
          <div>
            <h3>Edit Profile</h3>
          </div>
          {/*
          <div className="d-flex justify-content-center ">
          {spinner ? (
            <>
              <span className="custom-drag-box">
                <div
                  className="spinner-grow text-warning mx-auto"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
                <input
                  type="file"
                  id="uploadFile"
                  accept="image/png, image/gif, image/jpeg"
                  tabIndex="1"
                  title=" "
                  onChange={(e) => handleUpload(e)}
                />
              </span>
            </>
          ) : ProfileImage ? (
            <>
              <span className="custom-drag-box">
                <div className="d-flex flex-column align-items-center w-100 h-100">
                  <i
                    className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                    aria-hidden="true"
                  ></i>
                  <div
                    className="form-upload-photo-preview2"
                    style={{
                      backgroundImage: `url(${
                        UserInfo?.image ? UserInfo?.image : ProfileImage
                      })`,
                    }}
                  ></div>
                  <span className="custom-drag-box-text d-none">
                    Image Uploaded
                  </span>
                  <input
                    type="file"
                    id="uploadFile"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    tabIndex="1"
                    title=" "
                    onChange={(e) => handleUpload(e)}
                  />
                </div>
              </span>
            </>
          ) : (
            <>
              <span className="custom-drag-box">
                <img
                  className="reg-user-img"
                  src={UserInfo?.image ? UserInfo?.image : userImg}
                />
                <input
                  type="file"
                  id="uploadFile"
                  accept="image/png, image/gif, image/jpeg"
                  tabIndex="1"
                  title=" "
                  onChange={(e) => handleUpload(e)}
                />
              </span>
            </>
          )}
        </div>
        */}
          {ProfileImage ? (
            <div className="d-flex justify-content-center ">
              <span className="custom-drag-box">
                {/* <p style={{ textAlign: "Center" }}>Select Group Icon</p> */}
                <img className="group-img" src={ProfileImage} alt="profile" />
                <input
                  type="file"
                  id="uploadFile"
                  accept="image/png, image/gif, image/jpeg"
                  tabIndex="1"
                  title=" "
                  onChange={(e) => handleUpload(e)}
                />
              </span>
            </div>
          ) : (
            <div className="d-flex justify-content-center ">
              <span className="custom-drag-box">
                {/* <p style={{ textAlign: "Center" }}>Select Group Icon</p> */}
                <img className="group-img" src={plusIcon} alt="plus" />
                <input
                  type="file"
                  id="uploadFile"
                  accept="image/png, image/gif, image/jpeg"
                  tabIndex="1"
                  title=" "
                  onChange={(e) => handleUpload(e)}
                />
              </span>
            </div>
          )}
          <div className="input-container">
            <div className="form-el">
              <label className="pb-2">* First Name</label>
              <input
                type="text"
                name="Name"
                value={formData?.Name}
                placeholder="Enter your First Name"
                required
                onChange={(e) => handleChange(e)}
              />
              <p className="text-danger">{formErrors?.Name}</p>
            </div>

            {/*
           <div className="form-el">
              <label className="pb-2">* Last Name</label>
              <input
                type="text"
                name="Last"
                value={formData.Last}
                placeholder="Enter your Last Name"
                required
                onChange={(e) => handleChange(e)}
              />
              <p className="text-danger">{formErrors?.Last}</p>
            </div>
          */}

            <div className="form-el">
              <label className="pb-2">* DOB</label>
              <input
                type="date"
                value={
                  formData?.DOB &&
                  new Date(formData?.DOB).toISOString().split("T")[0]
                }
                name="DOB"
                placeholder="Enter quote you resonate with"
                required
                onChange={(e) => handleChange(e)}
              />
              <p className="text-danger">{formErrors?.DOB}</p>
            </div>

            <div className="bottom-btn-container w-100">
              <div className="col-12 col-sm-12 col-md-4 col-lg-3 px-0">
                <button className="sec-btn" onClick={(e) => handleSubmit(e)}>
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <MobMenuBar></MobMenuBar>
      </div>
    </div>
  );
};

export default ProfileEdit;
