import React from "react";
import RouteName from "./RouteDetails";
import Login from "./../Components/Auth/Login";
import Home from "../Components/Auth/Home";
import Registration1 from "../Components/Auth/Registration1";
import Registration2 from "../Components/Auth/Registration2";
import OurFeatures from "../Components/Auth/OurFeatures";
import NeedAnalysisForm from "../Components/Auth/NeedAnalysisForm";
import NeedAnalysis from "../Components/Auth/NeedAnalysis";
import SelectTherapist from "../Components/Pages/SelectTherapist";
import UpdatesFromTherapist from "../Components/Pages/UpdatesFromTherapist";
import Dashboard from "../Components/Pages/Dashboard";
import Profile from "../Components/Pages/Profile";
import ProfileEdit from "../Components/Pages/EditProfile";
import PersonalityTests from "../Components/Pages/PersonalityTests";
import WellBeingTest from "../Components/Pages/WellBeingTest";
import VerifyOtp from "../Components/Auth/VerifyOTP";
import VerifyOtpMail from "../Components/Auth/VerifyOtpMail";
import MyCalender from "../Components/Pages/MyCalender";
import MyActivityList from "../Components/Pages/MyActivityList";
import ChatBox from "../Components/Pages/Chatbox";
import Groups from "../Components/Pages/Goups/Groups";
import MembersSelection from "../Components/Pages/MembersSelection";
import ClientList from "../Components/Pages/ClientsList";
import ClientDetails from "../Components/Pages/ClientDetials";
import ClientViewDet from "../Components/Pages/ClientViewDet";
import Sessions from "../Components/Pages/Sessions";
import SessionDetails from "../Components/Pages/SessionDetails";
import Settings from "../Components/Pages/Settings";
import MyReferrel from "../Components/Pages/MyReferrel";
import SelectReferral1 from "../Components/Pages/SelectReferral1";
import SelectReferral2 from "../Components/Pages/SelectReferral2";
import DigitalNotes from "../Components/Pages/DigitalNotes";
import DigitalNotesDetails from "../Components/Pages/DigitalNotesDetails";
import CreateNotes from "../Components/Pages/CreateNotes";
import AiAsistance from "../Components/Pages/Aiassistant/AiAsistance";
import AddEditSessionDetails from "../Components/Pages/AddEditSessionDetails";
import Playground from "../Components/Pages/Playground";
import JoinSession from "../Components/Pages/JoinSession";
import FollowUpList from "../Components/Pages/FollowUpList";
import Notifications from "../Components/Pages/Notifications";
import RegisterGroup from "../Components/Pages/Goups/RegisterGroup";
import VideoCall from "../Components/VideoCall/VideoCall";
import LeaderShipForm from "../Components/Auth/LeaderShipForm";

// import ProtectedRoute from "./ProtectedRoute";
import ProtectedRoute from "./ProtectedRoute";

const AdminRoute = [
  {
    path: RouteName.Auth.Home,
    element: <Home />,
    exact: true,
  },
  {
    path: RouteName.Auth.Login,
    element: <Login />,
    exact: true,
  },
  {
    path: RouteName.Auth.Registration1,
    element: <Registration1 />,
    exact: true,
  },
  {
    path: RouteName.Auth.Registration2,
    element: <Registration2 />,
    exact: true,
  },
  {
    path: RouteName.Auth.OurFeatures,
    element: <OurFeatures />,
    exact: true,
  },
  {
    path: RouteName.Auth.NeedAnalysisForm,
    element: <NeedAnalysisForm />,
    exact: true,
  },
  {
    path: RouteName.Auth.LeaderShipForm,
    element: <LeaderShipForm />,
    exact: true,
  },
  {
    path: RouteName.Auth.NeedAnalysis,
    element: <NeedAnalysis />,
    exact: true,
  },
  {
    path: RouteName.Auth.SelectTherapist,
    element: <SelectTherapist />,
    exact: true,
  },
  {
    path: RouteName.Auth.UpdatesFromTherapist,
    element: <UpdatesFromTherapist />,
    exact: true,
  },
  {
    path: `${RouteName.Auth.VerifyOtp}/:path`,
    // 1: login
    // 2: registration
    element: <VerifyOtp />,
    exact: true,
  },

  {
    path: `${RouteName.Auth.verifyOtpMail}/:path`,
    element: <VerifyOtpMail />,
    exact: true,
  },
  {
    path: RouteName.Auth.Dashboard,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Dashboard />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.Profile,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Profile />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.ProfileEdit,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <ProfileEdit />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.PersonalityTests,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <PersonalityTests />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.WellBeingTest,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <WellBeingTest />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.MyCalender}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <MyCalender />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.MyActivityList,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <MyActivityList />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.ChatBox}/:GroupId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <ChatBox />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Auth.Groups,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Groups />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.MembersSelection}/:GroupId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <MembersSelection />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.ClientList}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <ClientList />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.ClientDetails}/:ClientId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <ClientDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.ClientViewDet}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <ClientViewDet />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.Sessions}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <Sessions />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.SessionDetails}/:SessionId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <SessionDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Video.VideoCall}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <VideoCall />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.Settings}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <Settings />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.MyReferrel}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <MyReferrel />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.SelectReferral1}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <SelectReferral1 />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.SelectReferral2}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <SelectReferral2 />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.DigitalNotes}/:NotesId`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <DigitalNotes />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.CreateNotes}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <CreateNotes />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.DigitalNotesDetails}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <DigitalNotesDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.AiAsistance}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <AiAsistance />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.AddEditSessionDetails}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <AddEditSessionDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.Playground}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <Playground />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.JoinSession}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <JoinSession />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.FollowUpList}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <FollowUpList />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Auth.Notifications}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <Notifications />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Auth.RegisterGroup}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        {" "}
        <RegisterGroup />
      </ProtectedRoute>
    ),
    exact: true,
  },

  // {
  //     path: RouteName.Auth.NewPassword,
  //     element: < NewPassword />,
  //     exact: true,
  // },
];

export default AdminRoute;
