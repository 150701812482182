import React, { useState, useEffect } from "react";
import Header from "../Common/Header";
import therapySession from "../../assets/images/dashboard/therapy-session.jpg";
import aiTherapist from "../../assets/images/dashboard/ai-therapist.jpg";
import groupImg from "../../assets/images/dashboard/groups.jpg";
import digitalNotes from "../../assets/images/dashboard/digital-notes.jpg";
import playgroundImg from "../../assets/images/dashboard/playground.jpg";
import wellnessTracker from "../../assets/images/dashboard/wellness-tracker.jpg";
import { useNavigate } from "react-router-dom";
import API from "../../Api/Api";

const OurFeatures = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [UpcommingList, setUpcommingList] = useState([]);
  const [ClientName, setClientName] = useState("");

  //api call for upcommming session

  const upcomingListfun = () => {
    try {
      API.Client.UpcomingListdashboard({
        UserId: UserData?.userdata?._id,
        isApproved: "1",
      }).then((response) => {
        if (response?.data?.status === 1) {
          setUpcommingList(response?.data?.data_all[0]);
          setClientName(
            `${response?.data?.data_all[0]?.client[0]?.name} ${response?.data?.data_all[0]?.client[0]?.last_name}`
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    upcomingListfun();
  }, []);

  return (
    <div className="app-container">
      {/* <div className="side-menu-panel">
                <div className="menu-panel-logo">
                    <img className="logo" src={rtylifeLogo} />
                </div>
                <div>
                    <SideBarMenu></SideBarMenu>
                </div>
            </div> */}
      <div className="content">
        <Header
          isBack={true}
          header={"Our Features"}
          // isShowIcons={true}
          isSkipBtn={true}
        ></Header>
        {/* <MobMenuBar></MobMenuBar> */}

        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 160px)" }}
        >
          {/* ===========Wellness Tracker============ */}

          <div className="row px-3 mt-3">
            <div className="col-md-12 col-lg-4 mb-3">
              <div className="dashboard-inner p-2 dashboard-inner-boxes h-100">
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={therapySession} alt="Therapy Session" />
                  </span>
                </div>

                <div className="dashboard-text-div px-3">
                  <h3 className="secheading mt-3">1-1 Therapy Session</h3>
                  <p>
                  Explore your inner world with a therapist who truly understands you, guiding you towards profound self-awareness and personal growth.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mb-3">
              <div className="dashboard-inner p-2 dashboard-inner-boxes h-100">
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={aiTherapist} alt="AI Therapist" />
                  </span>
                </div>

                <div className="dashboard-text-div yellow-gradient px-3">
                  <h3 className="secheading mt-3">AI Therapist</h3>
                  <p>
                  Experience continuous care with our AI Therapist, a groundbreaking tool providing personalised mental health assistance anytime, tailored to your unique emotional needs.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mb-3">
              <div className="dashboard-inner p-2 dashboard-inner-boxes h-100">
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={groupImg} alt="Groups" />
                  </span>
                </div>

                <div className="dashboard-text-div px-3">
                  <h3 className="secheading mt-3"> Groups</h3>
                  <p>
                  Find your tribe, share challenges, and unlock strength with other driven professionals.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mb-3">
              <div className="dashboard-inner p-2 dashboard-inner-boxes h-100">
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={digitalNotes} alt="Dgital Notes" />
                  </span>
                </div>

                <div className="dashboard-text-div px-3">
                  <h3 className="secheading mt-3">Digital Notes</h3>
                  <p>
                  Stay in constant touch with your therapist, using digital notes to express and lighten your emotional burdens every day.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mb-3">
              <div className="dashboard-inner p-2 mb-2 dashboard-inner-boxes h-100">
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={playgroundImg} alt="Playground" />
                  </span>
                </div>

                <div className="dashboard-text-div px-3">
                  <h3 className="secheading mt-3">Playground</h3>
                  <p>
                  Dive into a wealth of mental health resources designed to educate, inspire, and foster greater self-awareness on your journey to wellness.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mb-3">
              <div className="dashboard-inner p-2 dashboard-inner-boxes h-100">
                <div className="dashboard-img-div">
                  <span className="img">
                    <img src={wellnessTracker} alt="Wellness Tracker" />
                  </span>
                </div>

                <div className="dashboard-text-div yellow-gradient px-3">
                  <h3 className="secheading mt-3">Wellness Tracker</h3>
                  <p>
                  Regularly track both your physical and psychological health, gaining insights into your current wellbeing and future health trajectory.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurFeatures;
