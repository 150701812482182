import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import Modal from "react-modal";
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import AdminRoute from "../../Route/RouteDetails";
import userIcon from "../../assets/images/icons/Icon awesome-user-circle.png";
import searchIcon from "../../assets/images/icons/Icon ionic-md-search.png";
import API from "../../Api/Api";

const SelectReferral1 = () => {
  const navigate = useNavigate();
  const [ClientList, setClientList] = useState([]);

  useEffect(() => {
    try {
      API.Group.ClientListing({}).then((response) => {
        console.log(response?.data?.data?.dataorg);
        setClientList(response?.data?.data?.dataorg);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSelectClients = (e, ele) => {
    // console.log(e.target.value);
    console.log(ele);
    localStorage.setItem("ClientRefId", e.target.value);
    localStorage.setItem("ClientRefName", `${ele?.name} ${ele?.last_name}`);
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
      </div>
      <div className="content">
        <Header
          isBack={true}
          header={"Select Clients"}
          isShowIcons={false}
        ></Header>
        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 20%)" }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ position: "relative" }}
          >
            <span>
              <img src={searchIcon} className="search_ref_icon" />
            </span>
            <input placeholder="Search Therapist" className="search_ref" />
          </div>
          <button
            className="common-btn"
            onClick={() => navigate(`../${AdminRoute?.Auth?.SelectReferral2}`)}
          >
            Next
          </button>
          <div
            className="client-name-list"
            style={{ maxHeight: "calc(100% - 10%)" }}
          >
            {!ClientList?.length ? (
              <h3>Data not found</h3>
            ) : (
              ClientList?.length &&
              ClientList?.map((val, i) => {
                return (
                  <div className="client-item" key={i}>
                    <p>
                      <img
                        className="mx-2"
                        style={{ height: "26px" }}
                        src={userIcon}
                      />
                      {`${val?.name} ${val?.last_name}`}
                      <span>
                        <label className="check_container">
                          <input
                            name="Client"
                            type="radio"
                            value={val?._id}
                            onChange={(e) => handleSelectClients(e, val)}
                          />
                          <span
                            className="checkmark"
                            style={{ top: "-18px" }}
                          ></span>
                        </label>
                      </span>
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectReferral1;
