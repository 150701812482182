import React, { useState, useEffect } from "react";
import MobMenuBar from "../Common/MobMenuBar";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import userImage from "../../assets/images/icons/Path 15093.png";
import API from "../../Api/Api";
import AdminRoute from "../../Route/RouteDetails";
import { useNavigate, useParams } from "react-router-dom";
import plusIcon from "../../assets/images/icons/+.png";
import sendIcon from "../../assets/images/icons/Icon feather-send.png";
import moment from "moment";
// import userChatIcon from "../../assets/images/icons/Icon awesome-user-circle.png";

const ClientDetails = () => {
  const params = useParams();
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const naviagate = useNavigate();
  const [activeIndex, setIndex] = useState(0);
  const [DigitalNotes, setDigital_Notes] = useState([]);
  const [Session_History, setSession_History] = useState([]);

  const [UpcommingSession, setUpcommingSession] = useState([]);

  const handleTabs = (e) => {
    setIndex(e);
  };

  //APi call for Session_History
  const getSessionHistory_List = () => {
    try {
      API.Client.SessionHistory({
        ClientId: params?.ClientId,
        TherapistID: UserData?.userdata?._id,
      }).then((response) => {
        console.log("Session_History", response?.data?.data_all);
        setSession_History(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getUpcommingSeeion_List = () => {
    try {
      API.Client.UpcomingList({
        UserId: UserData?.userdata?._id,
        isApproved: "1",
        ClientId: params?.ClientId,
      }).then((response) => {
        console.log("UpcommingSession==>", response?.data?.data_all);
        setUpcommingSession(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //APi call for Digital Notes
  const getDigitalNotes_List = () => {
    try {
      API.Client.Digital_Notes({
        UserId: UserData?.userdata?._id,
      }).then((response) => {
        // console.log("ClientActiveList", response?.data?.data_all);
        setDigital_Notes(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //use effect for apis
  useEffect(() => {
    getSessionHistory_List();
    getDigitalNotes_List();
    getUpcommingSeeion_List();
  }, []);
  //jsx
  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
        <MobMenuBar></MobMenuBar>
        <div className="dashboard-container">
          <div className="sub-head px-3 d-flex">
            <img
              style={{ height: "40px", marginRight: "10px" }}
              src={userImage}
            />
            <div>
              <p className="sub-heading">
                <strong>{`${Session_History[0]?.client[0]?.name} ${Session_History[0]?.client[0]?.last_name}`}</strong>
              </p>
              <p className="sub-lbl">Here are all the details of your client</p>
            </div>
            <button className="common-sec-btn">View Details</button>
          </div>
          <div className="common-tabs cursor-pointer">
            <div
              className="common-tab-item"
              onClick={() => handleTabs(0)}
              style={
                activeIndex == 0
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Session Histroy
            </div>
            <div
              className="common-tab-item"
              onClick={() => handleTabs(4)}
              style={
                activeIndex == 4
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Upcomming Session
            </div>
            <div
              className="common-tab-item"
              onClick={() => handleTabs(1)}
              style={
                activeIndex == 1
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Digital Note
            </div>
            <div
              className="common-tab-item"
              onClick={() => handleTabs(2)}
              style={
                activeIndex == 2
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Activity
            </div>
            <div
              className="common-tab-item"
              onClick={() => handleTabs(3)}
              style={
                activeIndex == 3
                  ? { borderBottom: "2px solid rgb(245,189,64)" }
                  : null
              }
            >
              Chats
            </div>
          </div>
        </div>
        {activeIndex === 0 ? (
          <>
            <div className="client-name-list">
              {Session_History?.length &&
                Session_History?.map((val, i) => {
                  return (
                    <div
                      key={i}
                      style={{ padding: "18px 15px" }}
                      className="client-name-card d-flex flex-row"
                      onClick={() =>
                        naviagate(
                          `../${AdminRoute?.Auth?.SessionDetails}/${val?._id}`
                        )
                      }
                    >
                      <div className="w-half">
                        <p className="m-0 client-lbl1 m-0">Date</p>
                        <p className="client-lbl2 m-0">
                          {" "}
                          {moment(val?.date).format("ll")}
                        </p>
                      </div>
                      <div className="w-half">
                        <p className="m-0 client-lbl1 m-0">Time</p>
                        <p className="client-lbl2 m-0">
                          {moment(val?.updatedAt).format("LT")}
                        </p>
                      </div>
                      <img
                        style={{ top: "32px" }}
                        src={rightArrow}
                        className="right-arr cursor-pointer"
                        //  NavLink to={`/session-details`}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        ) : activeIndex === 1 ? (
          <>
            <div className="client-name-list">
              {DigitalNotes?.length &&
                DigitalNotes?.map((val, i) => {
                  return (
                    <div className="client-name-card" key={i}>
                      <p className="client-lbl2 ">{val?.title}</p>
                      <p className="client-lbl3 m-0">
                        {" "}
                        {moment(val?.createdAt).format("LL")}
                      </p>
                      <img
                        style={{ top: "38%" }}
                        src={rightArrow}
                        className="right-arr cursor-pointer"
                        onClick={(e) =>
                          naviagate(
                            `../${AdminRoute?.Auth?.DigitalNotes}/${val?._id}`
                          )
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </>
        ) : activeIndex === 2 ? (
          <>
            <div className="client-name-list">
              <div className="client-name-card">
                <p className="client-lbl2 ">Mindfullness - A state of Art</p>
                <p className="client-lbl3 m-0">Today 12:00 PM</p>
                <img
                  style={{ top: "38%" }}
                  src={rightArrow}
                  className="right-arr cursor-pointer"
                />
              </div>

              <div className="client-name-card">
                <p className="client-lbl2 ">Mindfullness - A state of Art</p>
                <p className="client-lbl3 m-0">Today 12:00 PM</p>
                <img
                  style={{ top: "38%" }}
                  src={rightArrow}
                  className="right-arr cursor-pointer"
                />
              </div>

              <div className="client-name-card">
                <p className="client-lbl2 ">Mindfullness - A state of Art</p>
                <p className="client-lbl3 m-0">Today 12:00 PM</p>
                <img
                  style={{ top: "38%" }}
                  src={rightArrow}
                  className="right-arr cursor-pointer"
                />
              </div>
            </div>
          </>
        ) : activeIndex === 4 ? (
          <>
            <div className="client-name-list">
              {UpcommingSession?.length &&
                UpcommingSession?.map((val, i) => {
                  return (
                    <div
                      key={i}
                      style={{ padding: "18px 15px" }}
                      className="client-name-card d-flex flex-row"
                      onClick={() =>
                        naviagate(
                          `../${AdminRoute?.Auth?.SessionDetails}/${val?._id}`
                        )
                      }
                    >
                      <div className="w-half">
                        <p className="m-0 client-lbl1 m-0">Date</p>
                        <p className="client-lbl2 m-0">
                          {" "}
                          {moment(val?.date).format("ll")}
                        </p>
                      </div>
                      <div className="w-half">
                        <p className="m-0 client-lbl1 m-0">Time</p>
                        <p className="client-lbl2 m-0">
                          {moment(val?.updatedAt).format("LT")}
                        </p>
                      </div>
                      <img
                        style={{ top: "32px" }}
                        src={rightArrow}
                        className="right-arr cursor-pointer"
                        //  NavLink to={`/session-details`}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            <div
              className="chats-main"
              style={{
                height: "calc(100% - 245px)",
                width: "98%",
                marginLeft: "1%",
              }}
            >
              <div
                className="scroller"
                style={{ maxHeight: "calc(100% - 73px)" }}
              >
                <div className="scroller-content" id="scrollerContent">
                  <div
                    className="item my-3 recive-msg"
                    style={{ margin: "10px 0px" }}
                  >
                    <div className="msg-card recived-msg-card">
                      <div className="mx-2 my-2 user-lbl">
                        {/* <img src={userChatIcon} className="mx-1 user-chat-img" /> */}
                        Akshay Mahajan
                      </div>
                      <div className="mx-2 my-2 msg-lbl">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Omnis dolores explicabo iure repudiandae, quo
                        repellat. Reiciendis facere maxime in commodi
                        voluptatibus quos hic optio, culpa magni, a, dolorem
                        recusandae cumque.
                      </div>
                      <div className="d-flex justify-content-end time-lbl ">
                        11:00 Am
                      </div>
                    </div>
                  </div>

                  <div
                    className="item recive-msg"
                    style={{ margin: "10px 0px" }}
                  >
                    <div className="msg-card recived-msg-card">
                      <div className="mx-2 my-2 user-lbl">
                        {/* <img src={userChatIcon} className="mx-1 user-chat-img" /> */}
                        Akshay Mahajan
                      </div>
                      <div className="mx-2 my-2 msg-lbl">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Omnis dolores explicabo iure repudiandae, quo
                        repellat. Reiciendis facere maxime in commodi
                        voluptatibus quos hic optio, culpa magni, a, dolorem
                        recusandae cumque.
                      </div>
                      <div className="d-flex justify-content-end time-lbl ">
                        11:00 Am
                      </div>
                    </div>
                  </div>

                  <div className="item send-msg" style={{ margin: "10px 0px" }}>
                    <div className="msg-card sent-mssg-card">
                      <div className="mx-2 my-2 user-lbl">
                        {/* <img src={userChatIcon} className="mx-1 user-chat-img" /> */}
                        Me
                      </div>
                      <div className="mx-2 my-2 msg-lbl">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Omnis dolores explicabo iure repudiandae, quo
                        repellat. Reiciendis facere maxime in commodi
                        voluptatibus quos hic optio, culpa magni, a, dolorem
                        recusandae cumque.
                      </div>
                      <div className="d-flex justify-content-end time-lbl ">
                        11:00 Am
                      </div>
                    </div>
                  </div>

                  <div className="chat-input-box">
                    <img className="plus-img" src={plusIcon} />
                    <input
                      className="chat-input-text"
                      type="text"
                      id="uploadFile"
                      accept="image/png, image/gif, image/jpeg"
                      tabIndex="1"
                      title=" "
                    />
                    <button className="send-btn">
                      <img src={sendIcon} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClientDetails;
