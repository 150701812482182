import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import rtylifeLogo from "../../../assets/images/Login/ryteLife-full.png";
import API from "../../../Api/Api";
import SideBarMenu from "../../Common/SideBarMenu";
import baseApi from "../../../Api/config";
import AdminRoute from "../../../Route/RouteDetails";
import MobMenuBar from "../../Common/MobMenuBar";
import Header from "../../Common/Header";

const Groups = () => {
  const navigate = useNavigate();
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [activeTab, setActiveTab] = useState(0);
  const [GroupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);

  const GroupListfun = (type, text) => {
    if (type === "focused") {
      setActiveTab(0);
    } else if (type === "support") {
      setActiveTab(1);
    }
    setLoading(true);
    try {
      API.Group.GroupListIN({
        data: {
          type: type,
          serchKey: text,
          inGroup: true,
        },
      }).then((response) => {
        if (response.data.status === 1) {
          console.log(type, response?.data?.data_all);
          setGroupList(response?.data?.data_all);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GroupListfun("focused");
  }, []);

  const gotoChat = (e, val) => {
    navigate(`../${AdminRoute.Auth.ChatBox}/${val?._id}`);
  };

  const handleSearchText = (text) => {
    if (activeTab === 0) {
      GroupListfun("focused", text);
    }
    if (activeTab === 1) {
      GroupListfun("support", text);
    }
  };

  return (
    <div className="app-container">
      <MobMenuBar></MobMenuBar>
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} alt="logo" />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header></Header>
       
        <div className="group-container">
          <div className="groups-tabs">
            <div
              className="grp-tab-item cursor-pointer"
              style={{
                background: activeTab === 0 ? "rgb(242,186,63)" : "white",
                color: activeTab === 0 ? "white" : "black",
              }}
              onClick={() => GroupListfun("focused")}
            >
              Focused
            </div>
            <div
              className="grp-tab-item cursor-pointer"
              style={{
                background: activeTab === 1 ? "rgb(242,186,63)" : "white",
                color: activeTab === 1 ? "white" : "black",
              }}
              onClick={() => GroupListfun("support")}
            >
              Support
            </div>
          </div>
          <div className="grp-search-cont">
            <input
              placeholder="Search Group"
              className="grp_search"
              onChange={(e) => handleSearchText(e.target.value)}
            />
          </div>

          <div className="group-list-container px-2">
            {loading ? (
              <div className="container_loader"></div>
            ) : (
              <>
                {!GroupList?.length ? (
                  <div className="alert alert-warning w-100" role="alert">
                    Data not available.
                  </div>
                ) : (
                  GroupList &&
                  GroupList?.map((val, i) => {
                    return (
                      <div
                        className="group-list-item"
                        key={i}
                        onClick={(e) => gotoChat(e, val)}
                      >
                        <div className="group-icon-container">
                          <img
                            src={`${baseApi.baseurl}images/${val?.logo}`}
                            alt="user logo"
                          />
                          <div>
                            <p className="group-name">{val?.name}</p>
                            <p className="grp-msg-lbl">{val?.description}</p>
                          </div>
                        </div>
                        <div className="text-end">
                          <p className="grp-msg-lbl">
                            {moment(val?.createdAt).format("ll")}
                          </p>
                          {/* <span className="text-end">
                        <label className="common-count mx-2">3</label>
                      </span> */}
                        </div>
                      </div>
                    );
                  })
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Groups;
