import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import backarrow from "../../assets/images/icons/back-arrow.png";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const path = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = JSON.parse(queryParams.get("email"));
  const newUSer = JSON.parse(queryParams.get("newUSer"));
  const Email = localStorage.getItem("EmailId");
  const AuthToken = localStorage.getItem("AuthTokenTherapist");
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [counter, setCounter] = useState(30);
  const [showPopup, setShowPopUp] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const numberOfDigits = 4;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);

  const goBackFun = () => {
    navigate(-1);
    localStorage.removeItem("EmailId");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Code to execute after 30 seconds
    }, 30000);

    const interval = setInterval(() => {
      if (counter) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [counter]);

  function handleChange(event, index) {
    const { value } = event.target;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  }
  const newotp = otp.join("");

  const handleVerifyOtp = (e) => {
    setIsSubmit(true);
    e.preventDefault();
    if (path.path == 2) {
      setLoading(true);
      try {
        API.Auth.VerifyViaMail({
          data: {
            email: email,
            otp: otp.join(""),
            role: "Cmsemployee",
          },
          tokenRequired: AuthToken,
        }).then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("AuthTokenClient", response?.data?.token);
            localStorage.setItem("cmsUserData", JSON.stringify(response?.data));
            setShowPopUp(true);
            console.log(response?.data?.userdata);
            if (newUSer) {
              navigate(`../${AdminRoute.Auth.OurFeatures}`);
            } else {
              if (response?.data.userdata.lead === "yes") {
                if (
                  response?.data.userdata.survay_employee_fill &&
                  response?.data.userdata.survay_lead_fill
                ) {
                  navigate(`../${AdminRoute?.Auth?.Dashboard}`);
                } else if (!response?.data.userdata.survay_employee_fill) {
                  navigate(`../${AdminRoute?.Auth.NeedAnalysisForm}`);
                  //condition added for to land on leadership from if its not filled  
                } else if (!response?.data.userdata.survay_lead_fill){
                  navigate(`../${AdminRoute?.Auth.LeaderShipForm}`);
                }
              } else {
                if (response?.data.userdata.survay_employee_fill) {
                  navigate(`../${AdminRoute?.Auth?.Dashboard}`);
                } else {
                  navigate(`../${AdminRoute?.Auth.NeedAnalysisForm}`);
                }
              }
            }

            // setTimeout(() => {
            //   setShowPopUp(false)
            //   // navigate(`../${AdminRoute.Auth.Dashboard}`);
            //    navigate(`../${AdminRoute.Auth.OurFeatures}`);
            // }, 2000)
          } else {
            setError(response?.data?.message);
            // toast.error(`${response.data.message}`);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setIsSubmit(false);
    }
    if (path.path == 1) {
      setLoading(true);
      try {
        API.Auth.VerifyViaMail({
          data: {
            email: email,
            otp: otp.join(""),
            role: "Cmsemployee",
          },
          tokenRequired: AuthToken,
        }).then((response) => {
          if (response?.data?.status === 1) {
            localStorage.setItem("AuthTokenClient", response?.data?.token);
            localStorage.setItem("cmsUserData", JSON.stringify(response?.data));
            setShowPopUp(true);
            console.log(response?.data.userdata);
            if (newUSer) {
              navigate(`../${AdminRoute.Auth.OurFeatures}`);
            } else {
              if (response?.data.userdata.lead === "yes") {
                if (
                  response?.data.userdata.survay_employee_fill &&
                  response?.data.userdata.survay_lead_fill
                ) {
                  navigate(`../${AdminRoute?.Auth?.Dashboard}`);
                } else if (!response?.data.userdata.survay_employee_fill) {
                  navigate(`../${AdminRoute?.Auth.NeedAnalysisForm}`);
                } else {
                  navigate(`../${AdminRoute?.Auth.LeaderShipForm}`);
                }
              } else {
                if (response?.data.userdata.survay_employee_fill) {
                  navigate(`../${AdminRoute?.Auth?.Dashboard}`);
                } else {
                  navigate(`../${AdminRoute?.Auth.NeedAnalysisForm}`);
                }
              }
            }

            // setTimeout(() => {
            //   setShowPopUp(false);
            //   navigate(`../${AdminRoute.Auth.Dashboard}`);
            // }, 2000);
          } else {
            // toast.error(`${response.data.message}`);
            setError(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setIsSubmit(false);
    }
  };

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  const handleResendOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      API.Auth.LoginAPIEmail({
        data: {
          email: email,
          role: "Cmsemployee",
          resend: true,
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          // toast.success(response?.data?.message);
          setError("");
        } else {
          setError(response?.data?.message);
          // toast.error(response?.data?.message);
        }
        setOtp(["", "", "", ""]);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="app-container d-block d-md-flex">
        <div className="logo-container">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div className="login-container">
          <div className="register-therapist-div register-therapist">
            <form>
              <h2 className="heading fw-600 py-2" onClick={() => goBackFun()}>
                <img className="back-arrow" src={backarrow} />
                Verify via E-mail OTP
              </h2>
              <div>
                <label className="pb-2">
                  * Enter OTP sent on the registered <b>&quot;{Email}&quot;</b>
                  Email-ID
                </label>
                <div
                  style={{ justifyContent: "space-evenly", display: "flex" }}
                >
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      value={digit}
                      maxLength={1}
                      onChange={(e) => handleChange(e.target.value, index)}
                      onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                      ref={(reference) =>
                        (otpBoxReference.current[index] = reference)
                      }
                      className="form-control custom-input-text-primary otp-input mb-0"
                      autoComplete="off"
                    />
                  ))}
                </div>
              </div>
              {error && <p className="text-danger">{error}</p>}
              <button
                type="submit"
                className="button_common w-100 mt-3 mb-3 text-center"
                role="button"
                onClick={(e) => handleVerifyOtp(e)}
              >
                Verify via OTP
              </button>
              {loading ? <div className="loader_orange"></div> : <></>}
              <div className="resend-link" onClick={(e) => handleResendOtp(e)}>
                {/* <span>Din't receive OTP </span> */}
                <strong className="cursor-pointer">Resend OTP</strong>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
