import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import notesLogo from "../../assets/images/icons/Group 22452.png";
import respIcon from "../../assets/images/icons/Icon feather-user.png";
import pencilIcon from "../../assets/images/icons/Icon feather-edit-2.png";
import { useParams } from "react-router-dom";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const DigitalNotes = () => {
  const param = useParams();
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [resp, setResp] = useState(false);
  const [DNotesResponse, setDNotesResponse] = useState("");

  const handleRespoClick = () => {
    setResp(true);
  };

  const handleEditClick = () => {
    setResp(false);
  };

  const handleSaveResponse = (e) => {
    e.preventDefault();
    try {
      API.Notes.UpdateNote({
        data: {
          response: {
            theropistid: UserData?.userdata?._id,
            theropistname: UserData?.userdata?.fullname,
            responce: DNotesResponse,
          },
        },
        NoteId: param?.NotesId,
      }).then((response) => {
        console.log(response?.data);
        if (response?.data?.status === 1) {
          //   toast.success("update successfull");
          setDNotesResponse("");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // side effect

  useEffect(() => {
    console.log(DNotesResponse);
  }, [DNotesResponse]);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
      </div>
      <div className="content">
        <Header
          isBack={true}
          header={"Digital Notes"}
          isShowIcons={false}
        ></Header>
        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 13%)", padding: "14px" }}
        >
          <div className="digital-note-cont">
            <div className="d-flex flex-row align-items-center ">
              <img src={notesLogo} style={{ height: "40px" }} />
              <div className="d-flex flex-column mx-2">
                <p className="p-2 m-0" style={{ fontSize: "16px" }}>
                  Mindfulness: A state of Art
                </p>
                {/* <p className="p-2 m-0" style={{ fontSize: "12px" }}>
                  Today, 11:00PM
                </p> */}
              </div>
            </div>
            <p style={{ fontSize: "14px" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo
              labore dolorum quam aliquam ipsam, at illum quia nobis neque natus
              pariatur minus obcaecati aperiam iure blanditiis inventore
              accusamus dicta earum. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Animi accusantium vero alias architecto dicta
              tenetur obcaecati doloribus qui iusto incidunt amet ea ullam dolor
              voluptatum, laudantium similique natus sunt laboriosam.
            </p>
            <p style={{ fontSize: "14px" }}>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo
              labore dolorum quam aliquam ipsam, at illum quia nobis neque natus
              pariatur minus obcaecati aperiam iure blanditiis inventore
              accusamus dicta earum. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Animi accusantium vero alias architecto dicta
              tenetur obcaecati doloribus qui iusto incidunt amet ea ullam dolor
              voluptatum, laudantium similique natus sunt laboriosam.
            </p>
            {!resp ? (
              <>
                <textarea
                  name="DNotesResponse"
                  className="respo_inp"
                  value={DNotesResponse}
                  placeholder="Add Response"
                  onChange={(e) => setDNotesResponse(e.target.value)}
                ></textarea>
              </>
            ) : (
              <></>
            )}
          </div>

          {!resp ? (
            <>
              <button
                className="btn_pr my-2"
                // onClick={() => handleRespoClick()} /// this for read only response view
                onClick={(e) => handleSaveResponse(e)}
              >
                Submit
              </button>
            </>
          ) : (
            <></>
          )}

          {resp ? (
            <>
              <div className="response-cont my-2">
                <div className="repsonse-head d-flex justify-content-between my-2 align-items-center">
                  <div>
                    <img src={respIcon} style={{ height: "18px" }} />
                    <label className="respo-head-lbl">Response</label>
                    <img
                      onClick={() => handleEditClick()}
                      src={pencilIcon}
                      style={{ height: "18px" }}
                    />
                  </div>
                  <div>
                    {/* <label>Today, 11:00 PM</label> */}
                  </div>
                </div>
                <p style={{ fontSize: "14px", margin: 0 }}>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nemo
                  labore dolorum quam aliquam ipsam, at illum quia nobis neque
                  natus pariatur minus obcaecati aperiam iure blanditiis
                  inventore accusamus dicta earum. Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Animi accusantium vero alias
                  architecto dicta tenetur obcaecati doloribus qui iusto
                  incidunt amet ea ullam dolor voluptatum, laudantium similique
                  natus sunt laboriosam.
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default DigitalNotes;
