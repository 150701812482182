import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import userImg from "../../assets/images/icons/userIMG.jpg";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import API from "../../Api/Api";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import RouteName from "../../Route/RouteDetails";
import PersonalityTests from "../../assets/images/Profile/personality-tests.jpg";
import WellBeingTest from "../../assets/images/Profile/well-being-test.jpg";
import LineChart from "../../assets/images/Profile/line-chart.jpg";
import RadarChart from "../../Components/Chart/RadarChart";
import moment from "moment";
import SideBarMenu from "../Common/SideBarMenu";

const Profile = () => {
  const navigate = useNavigate();
  const [UserInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [GraphData, setGraphData] = useState({});
  const [GraphOneData, setGraphOneData] = useState({});
  const [GraphTwoData, setGraphTwoData] = useState({});
  const [GraphThreeData, setGraphThreeData] = useState({});

  const config = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      labels: ["Engagement", "Leadership", "Burnout"],
    },
    series: [
      {
        name: "OHEP Index",
        data: [4.9, 3.9, 3.6],
      },
    ],
  };

  let config1 = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      labels: ["Engagement", "Leadership", "Burnout"],
    },
    series: [
      {
        name: "OHEP Index",
        data: [4.9, 3.9, 3.6],
      },
    ],
  };

  let config2 = {
    options: {
      chart: {
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      labels: ["Engagement", "Leadership", "Burnout"],
    },
    series: [
      {
        name: "OHEP Index",
        data: [4.9, 3.9, 3.6],
      },
    ],
  };

  let state = {
    series: [
      {
        name: "Joy",
        data: [28, 29, 33, 36, 32, 32, 33],
      },
      {
        name: "fodIntake",
        data: [12, 11, 14, 18, 17, 13, 13],
      },
      {
        name: "drive",
        data: [28, 19, 33, 20, 32, 32, 33],
      },
      {
        name: "sleep",
        data: [12, 11, 10, 18, 19, 13, 13],
      },
      {
        name: "stress",
        data: [28, 19, 33, 20, 32, 32, 33],
      },
      {
        name: "activity",
        data: [12, 11, 10, 18, 19, 13, 13],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [
        "#77B6EA",
        "#545454",
        "#F7E7CE",
        "#FF8F89",
        "#49F5FF",
        "#964B00",
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2, // Adjust the line width here
      },
      // title: {
      //   text: 'Average High & Low Temperature',
      //   align: 'left',
      // },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        title: {
          text: "Month",
        },
      },
      yaxis: {
        title: {
          text: "Temperature",
        },
        min: 5,
        max: 40,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
      setY: -25,
      offsetX: -5,
    },
  };

  useEffect(() => {
    try {
      API.Profile.GetProfileData({}).then((response) => {
        if (response?.data?.status === 1) {
          setUserInfo(response?.data?.data);
          console.log(response.data.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      API.Survey.AnswerSurvey().then((response) => {
        if (response.data.status === 1) {
          if (response?.data.graph1.length) {
            config.options.labels = response.data.graph1[0].keys;
            config.series[0].data = response.data.graph1[0].values;
            setGraphOneData(config);
          }
          if (response?.data.graph2.length) {
            config1.options.labels = Object.keys(response?.data.graph2[0]);
            config1.series[0].data = Object.values(response?.data.graph2[0]);
            setGraphTwoData(config1);
          }
          if (response.data.graph3.length) {
            state.series = response.data.graph3[0];
            console.log(state);
            setGraphThreeData(state);
          }
          setGraphData(response?.data);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const getInitialsLetters = (firstName, lastName) => {
    const firstInitial = firstName?.charAt(0);
    const lastInitial = lastName?.charAt(0);
    return `${firstInitial}${lastInitial}`;
  };

  const handleGraphValidation = (graph) => {
    API.Survey.GetTimit()
      .then((response) => {
        if (!response.data.personality) {
          if (graph === "PersonalityTests") {
            navigate(`../${RouteName?.Auth?.PersonalityTests}`);
          }
        }
        if (!response.data.wellbeing) {
          if (graph === "WellBeingTest") {
            navigate(`../${RouteName?.Auth?.WellBeingTest}`);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="app-container">
      <MobMenuBar></MobMenuBar>
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header isBack={true} header={"Profile"} isSetting={false}></Header>

        <div className="desc-container pb-4">
          <div>
            <h3 className="secheading ">Personal Details</h3>

            <p className="field-lbl mb-0">
              Here is an overview of your sessions letest activity.
            </p>
          </div>

          {/* <div className="img-container py-3 px-2">
            <span className="custom-drag-box">
              <img
                className="reg-user-img form-upload-photo-preview2 "
                src={UserInfo?.image ? UserInfo?.image : userImg}
                alt="ll"
              />
            </span>

            <p className="mb-3 mt-2 redText text-10">
                  Note: The pic should be 100 x 100 pixels.
                </p>
          </div> */}

          <div className="col-12 my-4">
            <div className="profile-image-box d-flex justify-content-center align-items-center mb-3">
              {UserInfo?.image ? (
                <img src={UserInfo?.image} alt=""></img>
              ) : (
                <p className="default-text text-center">
                  {getInitialsLetters(
                    UserInfo?.fullname?.split(" ")[0],
                    UserInfo?.fullname?.split(" ")[1]
                  )}
                </p>
              )}
            </div>
          </div>
          <div className="field-container-main">
            <div className="field-conteiner">
              <p>
                <label className="field-lbl">Full Name:</label>
                <label className="field-desc">{UserInfo?.fullname}</label>
              </p>
              {/*
            <p>
                <label className="field-lbl">Last Name:</label>
                <label className="field-desc">{UserInfo?.last_name}</label>
              </p>
          */}
              <p>
                <label className="field-lbl">Email Id:</label>
                <label className="field-desc">{UserInfo?.email}</label>
              </p>

              <p>
                <label className="field-lbl">Contact No:</label>
                <label className="field-desc">{UserInfo?.phone}</label>
              </p>
            </div>

            <div className="field-conteiner">
              <p>
                <label className="field-lbl">DOB:</label>
                <label className="field-desc">
                  {moment(UserInfo?.dob).format("DD/MM/YYYY")}
                </label>
              </p>

              <p>
                <label className="field-lbl">Designation:</label>
                <label className="field-desc">{UserInfo?.designation}</label>
              </p>
            </div>
          </div>
          {/*
        <div>
            <h4>Quote you resonate with</h4>
            <p>&quot;{UserInfo?.qote}&quot;</p>
          </div>
      */}
          <div className="bottom-btn-container w-100">
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 px-0">
              <button
                className="sec-btn"
                onClick={() => navigate(`../${RouteName?.Auth?.ProfileEdit}`)}
              >
                Edit Profile
              </button>
            </div>
          </div>
        </div>

        <div className="w-100 px-4 px-sm-0">
          <div className="mt-3">
            <h3 className="secheading mt-3">Wellness Map</h3>
            <p className="text-muted">Below are your profile details.</p>
            {loading ? (
              <div className="container_loader my-5"></div>
            ) : (
              <div className="wrapper-play mb-4 row p-0">
                <div className="col-sm-4 cursor-pointer">
                  <div className="box">
                    <div
                      className="card-body"
                      onClick={() => handleGraphValidation("PersonalityTests")}
                    >
                      {GraphData?.graph1?.length &&
                      Object.keys(GraphData?.graph1[0]).length !== 0 ? (
                        <RadarChart
                          options={GraphOneData.options}
                          series={GraphOneData.series}
                          type="radar"
                        />
                      ) : (
                        <div className="playground-img-div bg-grey mb-3">
                          <span className="img">
                            <img
                              src={PersonalityTests}
                              alt="PersonalityTests"
                            />
                          </span>
                        </div>
                      )}
                      <div className="support-div-text px-3">
                        <h3 className="card-heading text-left">
                          Personality Tests
                        </h3>
                        <p className="card-text mb-2 text-muted published-date">
                          {" "}
                          Published Date: 01 Sept, 2023
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 ">
                  <div className="box">
                    <div
                      className="card-body"
                      onClick={() => handleGraphValidation("WellBeingTest")}
                    >
                      {GraphData?.graph2?.length &&
                      Object.keys(GraphTwoData).length !== 0 ? (
                        <RadarChart
                          options={GraphTwoData.options}
                          series={GraphTwoData.series}
                          type="radar"
                        />
                      ) : (
                        <div className="playground-img-div bg-grey mb-3">
                          <span className="img">
                            <img src={WellBeingTest} alt="WellBeingTest" />
                          </span>
                        </div>
                      )}
                      <div className="support-div-text px-3">
                        <h3 className="card-heading text-left">
                          Well being Test
                        </h3>
                        <p className="card-text mb-2 text-muted published-date">
                          {" "}
                          Published Date: 01 Sept, 2023
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 mb-4">
                  <div className="box">
                    <div className="card-body">
                      {GraphData?.graph3?.length &&
                      Object.keys(GraphThreeData).length !== 0 ? (
                        <RadarChart
                          options={GraphThreeData.options}
                          series={GraphThreeData.series}
                          type="line"
                        />
                      ) : (
                        <div className="playground-img-div bg-grey mb-3">
                          <span className="img">
                            <img src={LineChart} alt="LineChart" />
                          </span>
                        </div>
                      )}
                      <div className="support-div-text px-3">
                        <h3 className="card-heading text-left">Line Chart</h3>
                        <p className="card-text mb-2 text-muted published-date">
                          {" "}
                          Published Date: 01 Sept, 2023
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <MobMenuBar></MobMenuBar>
    </div>
  );
};

export default Profile;
