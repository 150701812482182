import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import { useNavigate } from "react-router-dom";
import API from "../../Api/Api";

const NeedAnalysisForm = () => {
  const navigate = useNavigate();
  const [survayQuestions, setSurvayQuestins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));

  useEffect(() => {
    setLoading(true);
    API.NeedAnalysisFrom.NeedAnalysisSurvay()
      .then((responce) => {
        if (responce.data.status === 1) {
          setSurvayQuestins(responce.data.data);
          // console.log("setSurvayQuestins subquestion===========",responce.data.data[0]?.subquestion );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleTanSelect = (e, i) => {
    // Check if the index is valid
    if (i >= 0 && i < survayQuestions.length) {
      // Make a copy of the surveyQuestions array
      const updatedQuestions = [...survayQuestions];
      // Get the specific question object
      const question = updatedQuestions[i];
      // Initialize the new answers array
      const newAnswers = [];
      // Loop through the current answers array
      for (let j = 0; j < question.answers.length; j++) {
        // Check if the current index matches the selected tab index
        if (j === parseInt(e) - 1) {
          // Subtract 1 to match array index
          // Add a new object with the selected tab index set to true
          newAnswers.push({ [e]: true });
        } else {
          // Add a new object with the current index set to false
          newAnswers.push({ [j + 1]: false }); // Add 1 to match tab index
        }
      }
      // Update the answers array of the question with the new array
      question.answers = newAnswers;
      // Update the state with the modified array
      setSurvayQuestins(updatedQuestions);
    }
  };

  const handleAnwer = (checkvalue, val, i) => {
    const updatedData = [...survayQuestions];
    if (i >= 0 && i < survayQuestions.length) {
      updatedData[i].suitablereason.forEach((answer) => {
        if (Object.keys(answer)[0] === val) {
          answer[val] = checkvalue; // Set the current value to checkvalue
        } else {
          answer[Object.keys(answer)[0]] = false; // Set all other values to false
        }
      });
      setSurvayQuestins(updatedData);
    }
  };

  const handleChangeReson = (text, i) => {
    const updatedQuestions = [...survayQuestions]; // Create a copy of the array
    updatedQuestions[i] = { ...updatedQuestions[i], elaborate: text }; // Update the elaborate property of the specific question
    setSurvayQuestins(updatedQuestions); // Set the state with the updated array
  };
  function validateObject(obj) {
    if (obj && obj.answers && obj.suitablereason) {
      // Check if index 4 or 5 in answers array is true, if so, return true immediately
      if (obj.answers[3]["4"] === true || obj.answers[4]["5"] === true) {
        return true;
      }

      const isAnswerTrue = Object.values(obj.answers).some((ans) => {
        const value = Object.values(ans)[0];
        return value === true;
      });

      const isReasonTrue = Object.values(obj.suitablereason).some((reason) => {
        const value = Object.values(reason)[0];
        return value === true;
      });

      // Check if "Other" is true in suitablereason
      const isOtherTrue = obj.suitablereason.some((reason) => {
        return (
          Object.keys(reason)[0] === "Other" &&
          Object.values(reason)[0] === true
        );
      });

      // Check if "Other" is true and elaborate is not empty
      const isElaborateValid =
        !isOtherTrue || (isOtherTrue && obj.elaborate.trim() !== "");

      // Return true if any index in both arrays is true and elaborate is valid, otherwise false
      return isAnswerTrue && isReasonTrue && isElaborateValid;
    } else {
      return false; // Return false if obj, obj.answers, or obj.suitablereason is null or undefined
    }
  }
  // function validateObject(obj) {
  //   if (obj && obj.answers && obj.suitablereason) {
  //     const isAnswerTrue = Object.values(obj.answers).some((ans) => {
  //       const value = Object.values(ans)[0];
  //       return value === true;
  //     });

  //     // Check if index 3 or 4 is true in answers array
  //     const isIndex3or4True =
  //       Object.values(obj.answers[3])[0] || Object.values(obj.answers[4])[0];
  //     // If index 3 or 4 is true, only validate elaborate
  //     if (isIndex3or4True) {
  //       const isElaborateValid = obj.elaborate.trim() !== "";
  //       obj.error = !isElaborateValid;
  //       // Return true if elaborate is valid, otherwise false
  //       return isElaborateValid;
  //     } else {
  //       // Check if any index in suitablereason is true
  //       const isReasonTrue = Object.values(obj.suitablereason).some(
  //         (reason) => {
  //           const value = Object.values(reason)[0];
  //           return value === true;
  //         }
  //       );

  //       // Check if "Other" is true in suitablereason
  //       const isOtherTrue = obj.suitablereason.some((reason) => {
  //         return (
  //           Object.keys(reason)[0] === "Other" &&
  //           Object.values(reason)[0] === true
  //         );
  //       });

  //       // Check if "Other" is true and elaborate is not empty
  //       const isElaborateValid =
  //         !isOtherTrue || (isOtherTrue && obj.elaborate.trim() !== "");
  //       obj.error = isAnswerTrue && isReasonTrue && isElaborateValid;
  //       // Return true if any index in both arrays is true and elaborate is valid, otherwise false
  //       return isAnswerTrue && isReasonTrue && isElaborateValid;
  //     }
  //   } else {
  //     obj.error = false;
  //     return false; // Return false if obj, obj.answers, or obj.suitablereason is null or undefined
  //   }
  // }

  // function validateArray(arr) {
  //   // Iterate over each object in the array
  //   for (const obj of arr) {
  //     // Call validateObject for each object
  //     if (!validateObject(obj)) {
  //       // If any object fails validation, return false immediately
  //       return false;
  //     }
  //   }
  //   // If all objects pass validation, return true
  //   return true;
  // }
  function validateArray(arr) {
    // Iterate over each object in the array
    for (const obj of arr) {
      // Call validateObject for each object
      if (!validateObject(obj)) {
        // If any object fails validation, return false immediately
        return false;
      }
    }
    // If all objects pass validation, return true
    return true;
  }

  const handleSubmit = () => {
    // Check if all questions have a reason selected and 'Other' option requires an elaborate
    // const isValidSubmission = survayQuestions.every((question) => {
    //   const hasSelectedReason = question.suitablereason.some((reason) => {
    //     const reasonValue = Object.values(reason)[0];
    //     return reasonValue === true;
    //   });

    //   if (hasSelectedReason) {
    //     const otherSelected = question.suitablereason.find((reason) => {
    //       return Object.keys(reason)[0] === "Other";
    //     });

    //     if (otherSelected) {
    //       const isElaborateRequired = otherSelected.Other;
    //       return isElaborateRequired ? question.elaborate.trim() !== "" : true;
    //     }
    //     return true;
    //   }
    //   return false; // Reason not selected for this question
    // });

    if (validateArray(survayQuestions)) {
      // Perform the submission logic here
      console.log("Form submitted successfully!");
      handleApiCallNeed(survayQuestions);

      // if (UserData.userdata.lead !== "yes") {
      // } else {
      //   // handleAPiCallLeaderShip(survayQuestions);
      // }
    } else {
      // Notify the user to complete all required fields
      setError("Please complete all required fields.");
    }
  };

  const handleApiCallNeed = (newData) => {
    API.NeedAnalysisFrom.NeedAnalysisFromAnswer({
      data: {
        answer: newData,
      },
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          // Handle successful submission
          UpdateUserAPi();
          if (UserData?.userdata?.lead === "yes") {
            navigate(`../${AdminRoute?.Auth?.LeaderShipForm}`);
          } else {
            navigate(`../${AdminRoute?.Auth?.Dashboard}`);
          }
        } else {
          setError(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleAPiCallLeaderShip = (newData) => {
  //   API.Leadership.LeadershipFromAnswer({
  //     data: {
  //       answer: newData,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.data.status === 1) {
  //         // Handle successful submission
  //         navigate(`../${AdminRoute.Auth.Dashboard}`);
  //       } else {
  //         setError(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const UpdateUserAPi = () => {
    API.Auth.UpdateUser({
      data: {
        survay_employee_fill: true,
      },
    })
      .then((response) => {
        if (response?.data?.status === 1) {
          // Handle successful submission
        } else {
          setError(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} alt="logo" />
        </div>
        {/*        <div>
          <SideBarMenu></SideBarMenu>
        </div>*/}
      </div>
      <div className="content">
        <Header
          isBack={true}
          header={"Need Analysis Form"}
          isShowIcons={false}
        ></Header>
        <MobMenuBar></MobMenuBar>
        <p className="mt-2 fw-bold px-2">
          Rate each statement from 1 (Strongly Disagree) - 5 (Strongly Agree)
          and choose the best option if required.
        </p>
        <div
          className="dashboard-container analysis-form-v-height pb-5 pb-md-0"
          style={{ maxHeight: "calc(100% - 160px)" }}
        >
          {/* ===========Wellness Tracker============ */}

          <div className="row my-2">
            <div className="col-12 d-flex align-items-center px-4 px-md-3">
              <div className="d-flex align-items-center me-4">
                <p className="analysis-point me-2 fw-bold">1</p>
                <p className="fw-bold">Strongly Disagree</p>
              </div>
              <div className="d-flex align-items-center">
                <p className="analysis-point me-2 fw-bold">5</p>
                <p className="fw-bold">Strongly Agree</p>
              </div>
            </div>
          </div>

          <div className="row px-3 mt-3 need-analysis-form">
            {/*
          <div className="col-12 mb-4">
              <div className="d-flex justify-content-between mb-2">
                <p>Please Fill this Need Analysis Form</p>
                <p>1/20</p>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "25%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          */}

            {loading ? (
              <div className="container_loader"></div>
            ) : (
              survayQuestions &&
              survayQuestions?.map((val, i) => {
                return (
                  <div key={val._id} className="col-md-12 col-lg-4 mb-3">
                    <div className="dashboard-inner p-2 h-100">
                      <div className="dashboard-text-div">
                        <p className="px-3 pb-2">
                          {i + 1}. {val.question}
                        </p>

                        <div className="analysis-form p-3">
                          <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className="mb-3 ps-0"
                            onSelect={(e) => handleTanSelect(e, i)}
                          >
                            <Tab eventKey="1" title="1">
                              <p className="sessions-summery-text">
                                <p>{val?.subquestion}</p>
                                {val?.suitablereason.map((each, index) => {
                                  return (
                                    <>
                                      <div className="form-check" key={index}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="flexCheckDefault"
                                          checked={Object?.values(each)[0]}
                                          name={Object?.keys(each)[0]}
                                          onChange={(e) =>
                                            handleAnwer(
                                              e.target.checked,
                                              Object?.keys(each)[0],
                                              i,
                                              index
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexCheckDefault"
                                        >
                                          {Object?.keys(each)[0]}
                                        </label>
                                      </div>
                                      {Object?.keys(each)[0] === "Other" &&
                                        Object?.values(each)[0] === true && (
                                          <input
                                            type="text"
                                            className="form-control mt-2 bg-white"
                                            id="exampleFormControlInput1"
                                            placeholder="Please Enter Your Reason"
                                            value={val?.elaborate}
                                            onChange={(e) =>
                                              handleChangeReson(
                                                e.target.value,
                                                i
                                              )
                                            }
                                          ></input>
                                        )}
                                    </>
                                  );
                                })}
                              </p>
                            </Tab>
                            <Tab eventKey="2" title="2">
                              <p className="sessions-summery-text">
                                <p>{val?.subquestion}</p>

                                {val?.suitablereason.map((each, index) => {
                                  return (
                                    <>
                                      <div className="form-check" key={index}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={Object?.values(each)[0]}
                                          id="flexCheckDefault"
                                          onChange={(e) =>
                                            handleAnwer(
                                              e.target.checked,
                                              Object?.keys(each)[0],
                                              i,
                                              index
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexCheckDefault"
                                        >
                                          {Object?.keys(each)[0]}
                                        </label>
                                      </div>
                                      {Object?.keys(each)[0] === "Other" &&
                                        Object?.values(each)[0] === true && (
                                          <input
                                            type="text"
                                            className="form-control mt-2 bg-white"
                                            id="exampleFormControlInput1"
                                            placeholder="Please Enter Your Reason"
                                            value={val?.elaborate}
                                            onChange={(e) =>
                                              handleChangeReson(
                                                e.target.value,
                                                i
                                              )
                                            }
                                          ></input>
                                        )}
                                    </>
                                  );
                                })}
                              </p>
                            </Tab>
                            <Tab eventKey="3" title="3">
                              <p className="sessions-summery-text">
                                <p>{val?.subquestion}</p>

                                {val?.suitablereason.map((each, index) => {
                                  return (
                                    <>
                                      <div className="form-check" key={index}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={Object?.values(each)[0]}
                                          id="flexCheckDefault"
                                          onChange={(e) =>
                                            handleAnwer(
                                              e.target.checked,
                                              Object?.keys(each)[0],
                                              i,
                                              index
                                            )
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="flexCheckDefault"
                                        >
                                          {Object?.keys(each)[0]}
                                        </label>
                                      </div>
                                      {Object.keys(each)[0] === "Other" &&
                                        Object.values(each)[0] === true && (
                                          <input
                                            type="text"
                                            className="form-control mt-2 bg-white"
                                            id="exampleFormControlInput1"
                                            placeholder="Please Enter Your Reason"
                                            value={val.elaborate}
                                            onChange={(e) =>
                                              handleChangeReson(
                                                e.target.value,
                                                i
                                              )
                                            }
                                          ></input>
                                        )}
                                    </>
                                  );
                                })}
                              </p>
                            </Tab>
                            <Tab eventKey="4" title="4">
                              <p className="sessions-summery-text">
                                <input
                                  type="text"
                                  className="form-control mt-2 bg-white"
                                  id="exampleFormControlInput1"
                                  placeholder="Please Enter Your Reason"
                                  value={val.elaborate}
                                  onChange={(e) =>
                                    handleChangeReson(e.target.value, i)
                                  }
                                ></input>
                              </p>
                            </Tab>
                            <Tab eventKey="5" title="5">
                              <p className="sessions-summery-text">
                                <input
                                  type="text"
                                  className="form-control mt-2 bg-white"
                                  id="exampleFormControlInput1"
                                  placeholder="Please Enter Your Reason"
                                  value={val.elaborate}
                                  onChange={(e) =>
                                    handleChangeReson(e.target.value, i)
                                  }
                                ></input>
                              </p>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>

          <div className="row px-3 mt-3 mb-5 mb-md-0 justify-content-end">
            <div className="col-12 col-md-2">
              <p className="text-danger">{error}</p>
              <Button
                className="button_common m-0 px-3 w-100"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedAnalysisForm;
