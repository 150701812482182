import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import userImg from "../../assets/images/icons/userIMG.jpg";
import Modal from "react-modal";
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import SideBarMenu from "../Common/SideBarMenu";
import API from "../../Api/Api";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";

const MembersSelection = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height: "500px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#FFF",
      borderRadius: "20px",
      paddingTop: "70px",
      width: "90%",
      maxWidth: "500px",
    },
  };
  const params = useParams();
  const navigate = useNavigate();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [ClientList, setClientList] = useState([]);
  //   const [selectedfolder, setselectedfolder] = useState("");
  const [selectedMembers, setselectedMembers] = useState("");

  // Open Refferal model

  //   function openModal() {
  //     setIsOpen(true);
  //   }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    try {
      API.Group.ClientListing({}).then((response) => {
        console.log(response?.data?.data?.dataorg);
        setClientList(response?.data?.data?.dataorg);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  //   ====Select Menbers====

  // const selectMember = (e) => {
  //   // setselectedMembers(e.target.value);
  //   // console.log(e.target.value)
  //   setselectedMembers((prevSelectedCheckboxes) => {
  //     if (prevSelectedCheckboxes.includes(e.target.value)) {
  //       // Checkbox was previously selected, remove it from the array
  //       return prevSelectedCheckboxes.filter(
  //         (value) => value !== e.target.value
  //       );
  //     } else {
  //       // Checkbox was not previously selected, add it to the array
  //       return [...prevSelectedCheckboxes, e.target.value];
  //     }
  //   });
  // };

  // const upadateGroupmembers = (e) => {
  //   try {
  //     API.Group.UpdateGroups({
  //       data: {
  //         id: params.GroupId,
  //         group_member: selectedMembers,
  //       },
  //     }).then((response) => {
  //       console.log(response?.data);
  //       if (response?.data?.message === "Record Updated Success") {
  //         navigate(`${AdminRoute?.Auth?.Groups}`);
  //         toast.success(response?.data?.message);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(error.message);
  //   }
  // };

  // ======= select members =======

  const selectMembersGroup = (e, value) => {
    console.log(value?._id);

    try {
      API.Group.SelectGroupMembers({
        data: {
          userId: value?._id,
          groupId: params.GroupId,
        },
      }).then((response) => {
        console.log(response?.data);
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  //   test effect

  useEffect(() => {
    console.log("selectedMembers", selectedMembers);
  }, [selectedMembers]);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <div className="app-header">
          <label className="header-lbl">Memebers Selection</label>
          <div>
            <button
              // onClick={() => openModal()}
              // onClick={(e) => upadateGroupmembers(e)}
              onClick={() => navigate(`../${AdminRoute?.Auth?.Groups}`)}
              className="next-btn"
            >
              Next
            </button>
          </div>
        </div>
        <div className="group-container">
          <div className="my-2">
            <input
              type="text"
              placeholder="Search Members"
              name="Title"
              className="search_inp "
            />
          </div>
          <div className="group-list-container py-0">
            {ClientList?.length &&
              ClientList?.map((val, i) => {
                return (
                  <div className="group-list-item px-3" key={i}>
                    <div className="group-icon-container">
                      <img className="memeber-img" src={userImg} />
                      <p className="m-0 mx-2">{val?.name}</p>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="user-check"
                        name={val?.name}
                        value={val?._id}
                        onChange={(e) => selectMembersGroup(e, val)}
                        // onChange={(e) => selectMember(e)}
                        // checked={selectedMembers == val?._id ? true : false}
                      />
                    </div>
                  </div>
                );
              })}
            {/* <div className="group-list-item px-3">
              <div className="group-icon-container">
                <img className="memeber-img" src={userImg} />
                <p className="m-0 mx-2">Akshay Mahajan</p>
              </div>
              <div>
                <input type="checkbox" className="user-check" />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className="close-btn ">
          <img src={closeBtn} />{" "}
        </button>
        <p>Client: Meenakshi Mukharjee</p>
        <p>Theropist: Brijesh Mukharjee</p>
        <p>Referring to:</p>
        <p>Email Id</p>
        <input
          type="email"
          placeholder="Email Id"
          className="mb-3"
          name="Title"
        />
        <p>Contact No.</p>
        <input
          type="text"
          placeholder="Contact No."
          className="mb-3"
          name="Title"
        />
        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
        >
          Done
        </button>
      </Modal>
    </div>
  );
};

export default MembersSelection;
