import React, { useState, useEffect } from "react";
import userImage from "../../assets/images/icons/mentalhealthart-01.png";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import MobMenuBar from "../Common/MobMenuBar";
import { useParams } from "react-router-dom";
import API from "../../Api/Api";
import moment from "moment";

const SessionDetails = () => {
  const param = useParams();
  const [SessionDetails, setSessionDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetSessionDetails = () => {
    setLoading(true);
    try {
      API.Client.SessionDetails({
        SessionId: param?.SessionId,
      }).then((response) => {
        console.log("SessionDetails", response?.data);
        if (response?.data?.status === 1) {
          setSessionDetails(response?.data?.data_all[0]);
        }
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    GetSessionDetails();
  }, []);

  //   side Effect==

  useEffect(() => {
    console.log("SessionDetails", SessionDetails);
  }, [SessionDetails]);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">

        <Header
          isBack={true}
          isShowIcons={false}
        ></Header>

        <MobMenuBar></MobMenuBar>
        <div className="dashboard-container pt-1">
          <div className="sub-head px-3 d-flex align-items-center">
            <img
              style={{ height: "40px", marginRight: "10px" }}
              src={userImage}
              alt=""
            />
            <div>
              <p className="sub-heading">
                {/* <strong>31 July 2023 11:00 AM</strong> */}
                <strong>{moment(SessionDetails?.timeend).format("LLL")}</strong>
              </p>
              <p className="sub-lbl">Here are all your session details</p>
            </div>
          </div>
          {loading ? (
            <div className="alert alert-warning" role="alert">
              Loading ...
            </div>
          ) : (
            <>
              {SessionDetails?.summary ? (
                <div className="mb-2 client-view-cont">
                  <p className="my-2" style={{ color: "#32665F" }}>
                    <strong>Session Summary:</strong>
                  </p>
                  <p>{SessionDetails?.summary}</p>
                  {/* <p className="my-2" style={{ color: "#32665F" }}>
                    <strong>Observations:</strong>
                  </p> */}
                  {/* <p>{SessionDetails?.observation}</p>

                  <p className="my-2" style={{ color: "#32665F" }}>
                    <strong>Interventions:</strong>
                  </p>
                  <p>{SessionDetails?.intervention}</p> */}
                </div>
              ) : (
                <div className="alert alert-warning" role="alert">
                  Summary not added from wellness coach
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionDetails;
