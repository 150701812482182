import React from "react";
import HomeIcon from "../../assets/images/icons/HomeIcon.png";
import SessionsIcon from "../../assets/images/icons/SessionsIcon.png";
import Groupsicon from "../../assets/images/icons/Groupsicon.png";
import AiIcon from "../../assets/images/icons/AiIcon.png";
import activityListIcon from "../../assets/images/icons/DigitalNotesIcon.png";
// import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import logoutArrow from "../../assets/images/icons/back-arrow.png";

const SideBarMenu = () => {
  const navigate = useNavigate();

  const logoutFun = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate(`../${AdminRoute?.Auth?.Login}`);
  };
  return (
    <div className="side-menu-container">
      <div
        className="side-manu-item cursor-pointer"
        onClick={() => navigate(`../${AdminRoute?.Auth?.Dashboard}`)}
      >
        <img src={HomeIcon} />
        Home
      </div>

      <div
        className="side-manu-item cursor-pointer"
        onClick={() => navigate(`../${AdminRoute?.Auth?.MyActivityList}`)}
      >
        <img src={activityListIcon} />
        Digital Notes
      </div>

      <div
        className="side-manu-item cursor-pointer"
        onClick={() => navigate(`../${AdminRoute?.Auth?.Sessions}`)}
      >
        <img src={SessionsIcon} />
        Sessions
      </div>
      <div
        className="side-manu-item cursor-pointer"
        onClick={() => navigate(`../${AdminRoute?.Auth?.Groups}`)}
      >
        <img src={Groupsicon} />
        Groups
      </div>
      <div
        className="side-manu-item cursor-pointer"
        onClick={() => navigate(`../${AdminRoute?.Auth?.AiAsistance}`)}
      >
        <img src={AiIcon} />
        Ai Assistance
      </div>
      <div
        className="side-manu-item cursor-pointer"
        onClick={(e) => logoutFun(e)}
      >
        <img src={logoutArrow} />
        Logout
      </div>
    </div>
  );
};

export default SideBarMenu;
