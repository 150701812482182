/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import sendIcon from "../../assets/images/icons/Icon feather-send.png";
import Header from "../Common/Header";
import pdfIcon from "../../assets/images/icons/pdf.png";
import API from "../../Api/Api";
import baseApi from "../../Api/config";
import moment from "moment";
import MobMenuBar from "../Common/MobMenuBar";
import SideBarMenu from "../Common/SideBarMenu";
import { useLocation } from "react-router-dom";
import { getInitialsLetters } from "../Common/Function";
import { socket } from "../../socket";

const UpdatesFromTherapist = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const TherapistID = queryParams.get("id");
  const [room] = useState("messageroom");
  const [currMessage, setCurrMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [rows, setRows] = useState(1);
  const [MediaList, setMediaList] = useState([]);
  const MediaListFun = () => {
    // setLoading(true);
    try {
      API.MyThreapist.meadialistindividual({
        Userid: UserData?.userdata?._id,
        // Userid: 123456789,
      }).then((response) => {
        // console.log("media List  ===>", response?.data);
        setMediaList(response?.data?.data_all);
        // setLoading(false);
      });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  const getAllChats = () => {
    // setLoading(true);
    API.MyThreapist.ChatList()
      .then((response) => {
        if (response.data.status === 1) {
          setMessageList(response?.data?.data_all);
          // console.log(response?.data?.data_all);
          //   setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // console.log(currMessage);
      setRows(1);
      sendMessage(e);
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        currMessage.substring(0, cursorPosition) +
        "\n" +
        currMessage.substring(cursorPosition);
      setCurrMessage(newInputValue);
      e.preventDefault();
    }
  };

  const sendMessage = async () => {
    if (currMessage !== "") {
      const messageData = {
        author: `${UserData?.userdata?.fullname}`,
        created_id: UserData?.userdata?._id,
        receiver_id: TherapistID
          ? TherapistID
          : UserData?.data_theropist[0]?.theropistId,
        message: currMessage,
        type: "individual",
        // type: "all",
      };
      await socket.emit(room, messageData);
    }
    setCurrMessage("");
  };


  useEffect(() => {
    if (!socket || !room) return;

    const handleData = (data) => {
      setMessageList((list) => [...list, data]);
    };

    // Attach the listener for incoming data
    socket.on(room, handleData);

    // Cleanup function to detach the listener and disconnect the socket
    return () => {
      // Remove the event listener
      socket.off(room, handleData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, room]);

  useEffect(() => {
    MediaListFun();
    getAllChats();
  }, []);

  return (
    <div className="app-container">
      <MobMenuBar></MobMenuBar>
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} alt="logo" />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header isBack={true}></Header>
        <div className="chats-container">
          <div className="tabs-container">
            <div
              className="tab-item cursor-pointer"
              onClick={() => setActiveIndex(0)}
              style={{
                borderBottom:
                  activeIndex === 0 ? "solid 3px rgb(249,221,163)" : "",
              }}
            >
              Activity
            </div>
            <div
              className="tab-item cursor-pointer"
              style={{
                borderBottom:
                  activeIndex === 1 ? "solid 3px rgb(249,221,163)" : "",
              }}
              onClick={() => setActiveIndex(1)}
            >
              Messages
            </div>
          </div>
          <div className="chats-main">
            {activeIndex === 1 ? (
              <>
                <div className="scroller group-chatbox">
                  <div className="scroller-content" id="scrollerContent">
                    {/* recieve messge  */}
                    {messageList.map((messageContent, i) => {
                      return (
                        <div
                          key={messageContent.author + i}
                          className={
                            UserData?.userdata?._id ===
                            messageContent.created_id
                              ? "item my-3 recive-msg"
                              : "item my-3 send-msg"
                          }
                        >
                          <div
                            className={
                              UserData?.userdata?._id ===
                              messageContent?.created_id
                                ? "msg-card recived-msg-card"
                                : "msg-card sent-mssg-card"
                            }
                          >
                            <div className="mx-2 my-2 user-lbl d-flex align-items-center">
                              <p className="user-icon-avatar rounded-circle text-center mb-0 me-2 text-white">
                                {getInitialsLetters(
                                  messageContent.author
                                    ? messageContent.author
                                    : messageContent?.created_user[0]?.fullname?.split(
                                        " "
                                      )[0],
                                  messageContent.author
                                    ? messageContent.author
                                    : messageContent?.created_user[0]?.fullname?.split(
                                        " "
                                      )[1]
                                )}
                              </p>
                              {UserData?.userdata?._id !==
                              messageContent?.created_id
                                ? messageContent?.created_user?.length &&
                                  messageContent?.created_user[0]?.fullname
                                : UserData?.userdata?.fullname}
                            </div>
                            <div className="mx-2 my-2 msg-lbl">
                              {messageContent.message}
                            </div>
                            <div className="d-flex justify-content-end time-lbl ">
                              {messageContent.time}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : activeIndex === 0 ? (
              <>
                <div className="scroller group-chatbox">
                  <div className="scroller-content" id="scrollerContent">
                    {/* Event  */}

                    <div className="row add">
                      {MediaList?.length === 0 ? (
                        <div className="alert alert-warning my-3" role="alert">
                          Data not found
                        </div>
                      ) : (
                        MediaList?.map((val) => (
                          <div
                            className="col-xl-3 col-lg-6 col-md-6 mb-3"
                            key={val?._id}
                          >
                            <div className="panel panel-default h-100 bg-grey">
                              <div className="drop_box h-100 activity-cards sent-activity-card p-4 w-100">
                                <a
                                  href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div
                                    className="d-flex pdf-div cursor_pointer"
                                    // onClick={() => handleDownload(val?.mediafile)}
                                  >
                                    <div className="file-div justify-content-evenly w-100">
                                      <img
                                        src={pdfIcon}
                                        style={{ height: "38px" }}
                                        alt="pdf icon"
                                      />
                                      <p
                                        style={{
                                          maxWidth: "100px",
                                          wordWrap: "break-word",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {val?.originalfilename}
                                      </p>
                                    </div>
                                    <span
                                      className={
                                        val?.type === "pdf"
                                          ? "pdf-icon"
                                          : "media-icon"
                                      }
                                    ></span>
                                    {/* <span className="pdf-text"> Download</span> */}
                                  </div>
                                </a>

                                {/* {val?.type === "pdf" ? (
                                  <h6>{val?.mediafile?.substr(34)}</h6>
                                ) : (
                                  <h6>{val?.mediafile}</h6>
                                )} */}
                                <p className="mb-1 mt-4">
                                  Media Type: {val?.type}
                                </p>
                                <p className="mb-0">
                                  {moment(val?.createdAt).format("LL")}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>

                    {/* sample card  */}

                    {/* <div className="item my-3 send-msg">
                      <div
                        className="msg-card sent-mssg-card"
                        style={{ background: "white" }}
                      >
                        <div className="mx-2 my-2 user-lbl">
                          <img
                            src={userChatIcon}
                            className="mx-1 user-chat-img"
                          />
                          Me
                        </div>
                        <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                          Which of the following method do you prefer ?
                        </div>
                        <div className="mx-2 my-2 msg-lbl">
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Omnis dolores explicabo iure repudiandae, quo
                          repellat. Reiciendis facere maxime in commodi
                          voluptatibus quos hic optio, culpa magni, a, dolorem
                          recusandae cumque.
                        </div>

                        {!showRes ? (
                          <>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(true)}
                            >
                              <div
                                className="first-load"
                                style={{ width: "70%", background: "white" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>Journal Writing</label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(true)}
                            >
                              <div
                                className="first-load"
                                style={{
                                  width: "30%",
                                  backgroundColor: "white",
                                }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>One on One Discussion</label>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(false)}
                            >
                              <div
                                className="first-load fi-bg "
                                style={{ width: "70%" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>Journal Writing</label>
                                  <label>70%</label>
                                </div>
                              </div>
                            </div>
                            <div
                              className="bacground-load"
                              onClick={() => setRes(false)}
                            >
                              <div
                                className="first-load fi-bg "
                                style={{ width: "30%" }}
                              >
                                <div className="d-flex justify-content-between final-fi">
                                  <label>One on One Discussion</label>
                                  <label>30%</label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="d-flex justify-content-between time-lbl ">
                          <label>Here is the result so far</label>
                          <label>11:00 Am</label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            ) : activeIndex === 2 ? (
              <>
                <div className="scroller group-chatbox">
                  {!MediaList?.length ? (
                    <div className="alert alert-warning my-3" role="alert">
                      Data not found
                    </div>
                  ) : (
                    MediaList?.length &&
                    MediaList?.map((val, i) => {
                      return (
                        <div
                          key={i}
                          className="scroller-content"
                          id="scrollerContent"
                        >
                          <div className="item my-3 send-msg">
                            {val?.type === "pdf" ? (
                              <div
                                className="msg-card sent-mssg-card"
                                style={{ background: "#E0DAD2" }}
                              >
                                <a
                                  href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className="file-div justify-content-evenly">
                                    <img
                                      src={pdfIcon}
                                      style={{ height: "38px" }}
                                      alt="pdf icon"
                                    />
                                    <p
                                      style={{
                                        maxWidth: "100px",
                                        wordWrap: "break-word",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      {val?.originalfilename}
                                    </p>
                                  </div>
                                  <p
                                    className="my-1 mt-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {val?.originalfilename}
                                  </p>
                                  <p
                                    className="my-1"
                                    style={{ fontSize: "12px" }}
                                  >
                                    Media Type: Blog
                                  </p>
                                  <p
                                    className="my-1"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {/* Today, 12:00PM */}
                                    {moment(val?.createdAt).format("ll")}
                                  </p>
                                </a>
                              </div>
                            ) : (
                              <div
                                className="msg-card sent-mssg-card"
                                style={{ background: "#E0DAD2" }}
                              >
                                <a
                                  href={`${baseApi.baseurl}images/${val?.mediafile}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <div className="file-div justify-content-evenly">
                                    <img
                                      src={`${baseApi?.baseurl}images/${val?.mediafile}`}
                                      style={{
                                        maxHeight: "49px",
                                        maxWidth: "88px",
                                      }}
                                      alt="media"
                                    />
                                    image.png
                                  </div>
                                  <p
                                    className="my-1"
                                    style={{ fontSize: "11px" }}
                                  >
                                    {/* Today, 12:00PM */}
                                    {moment(val?.createdAt).format("ll")}
                                  </p>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            {activeIndex === 1 && (
              <div className="chat-input-box chat-input-box-client justify-content-evenly cursor-pointer mb-0">
                <input
                  className="chat-input-text"
                  type="text"
                  id="uploadFile"
                  accept="image/png, image/gif, image/jpeg"
                  tabIndex="1"
                  title=" "
                  cols={50}
                  value={currMessage}
                  onChange={(event) => setCurrMessage(event.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <button className="send-btn" onClick={() => sendMessage()}>
                  <img src={sendIcon} alt="" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatesFromTherapist;
