import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import Button from 'react-bootstrap/Button';
import AdminRoute from "../../Route/RouteDetails";
import { useNavigate } from "react-router-dom";
import WellBeignTable from "../Pages/WellBeignTable";
import API from "../../Api/Api";

const WellBeingTest = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [allResourceData, setAllResourceData] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [loadingList, setLoadingList] = useState(false);
    const [error, setError] = useState("");


    useEffect(() => {
        getsuravayDetails();
    }, []);

    const getsuravayDetails = () => {
        setLoadingList(true)
        try {
            API.Survey.Surveylist({
                title: "well",
            }).then((response) => {
                if (response.data.status === 1) {
                    setAllResourceData(response?.data?.data_all[0]);
                    const trueAnswerIdAndValue = {};
                    response?.data?.data_all[0].subsurvay?.forEach((question) => {
                        const trueAnswer = question.answers.find((answer) => Object.values(answer)[0] === true);

                        if (trueAnswer) {
                            trueAnswerIdAndValue[question._id] = Object.keys(trueAnswer)[0];
                        }
                    });
                    setSelectedAnswers(trueAnswerIdAndValue)
                }
                setLoadingList(false)
            });
        } catch (error) {
            console.log(error);
            setLoadingList(false)
        }
    };

    const handleSave = () => {
        // Check if all checkboxes are selected
        const areAllCheckboxesSelected = Object.keys(selectedAnswers).length === allResourceData.subsurvay.length;
        if (!areAllCheckboxesSelected) {
            // Show an alert or take any other action to inform the user that all checkboxes must be selected
            setError('Please select an answer for each question before submitting.');
            return;
        } else {
            setError("")
        }


        const newData = allResourceData.subsurvay.map(({ _id, ...rest }) => rest);

        API.Survey.SubmitSurvay({
            data: {
                data: newData,
                isupdate: allResourceData?.isupdate,
                survayType: "wellbeing"
            }
        })
            .then((response) => {
                if (response.data.status === 1) {
                    if (response.data.status === 1) {
                        handleShow()
                        setError("")
                        setTimeout(() => {
                            handleClose()
                            navigate(`..${AdminRoute.Auth.Profile}`);
                        }, 2000)
                    }
                } else {
                    setError(response.data.message)
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="app-container">
            <div className="side-menu-panel">
                <div className="menu-panel-logo">
                    <img className="logo" src={rtylifeLogo} />
                </div>
                <div>
                    <SideBarMenu></SideBarMenu>
                </div>
            </div>
            <div className="content">
                <Header
                    isBack={true}
                    header={"Well Being Test"}
                    isShowIcons={false}
                ></Header>
                <MobMenuBar></MobMenuBar>
                <div
                    className="dashboard-container analysis-form-v-height"
                    style={{ maxHeight: "calc(100% - 160px)" }}
                >

                    {/* ===========Wellness Tracker============ */}


                    <div className="row px-3 mt-3 need-analysis-form">

                        <div className="col-12 mb-2">
                            <p>Here is an overview of your session.</p>
                            <p>Please answer the following questions, which are about how have you been feeling for the past month.</p>
                            <p>Place a check mark on the box that best represents how often have you experienced or felt the following:
                            </p>
                        </div>

                        <div className="col-md-12 mb-3">
                            <div className="dashboard-inner p-2 h-100 bg-white">

                                <div className="welltest-table mt-2 mb-2">
                                    <WellBeignTable
                                        allResourceData={allResourceData}
                                        setAllResourceData={setAllResourceData}
                                        selectedAnswers={selectedAnswers}
                                        setSelectedAnswers={setSelectedAnswers}
                                        loadingList={loadingList}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <p className="text-danger">{error}</p>
                    <div className="row px-3 mt-3 justify-content-end">
                        <div className="col-12 col-md-2">
                            <Button
                                className="button_common m-0 px-3 w-100"
                                onClick={() => handleSave()}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default WellBeingTest;
