import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import baseApi from "../../Api/config";
import RouteName from "../../Route/RouteDetails";
import API from "../../Api/Api";

const CreateNotes = () => {
    const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const noteId = queryParams.get("id");
  const authToken = localStorage.getItem("AuthTokenClient");
     
    const navigate = useNavigate();
    // date time
    const CurrentTime = moment().format("LT"); // htmlFor current Time
    const CurrentDate = moment().format("L"); // htmlFor current Date
    const initialValues = {
        Title: "",
        Description: "",
    };
    const [CreateNotes, setCreateNotes] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [Theamlist, setTheamlist] = useState([]);
    const [image, setimage] = useState("");
    const [image_id, setimage_id] = useState("");
    const [loadingTheam, setLoadingTheam] = useState(false);
    const [DigitalNotesdetails, setDigitalNotesdetails] = useState([]);
    const [loadingNotes, setLoadingNotes] = useState(false);

    useEffect(() => {
        if (noteId) {
            setLoadingNotes(true)
            try {
                API.Auth.DigitalNotesDetails({
                    notes_id: noteId
                }).then((response) => {
                    if (response.status === 200) {
                        setDigitalNotesdetails(response?.data?.data_all);
                        setLoadingNotes(false);
                    }
                });
            } catch (error) {
                console.log(error);
                setLoadingNotes(false);
            }
        }
    }, [])


    const CreateNoteValidate = (value) => {
        const errors = {};
        var titleregx = /^[a-zA-Z]+ [a-zA-Z]+$/;

        // if (!value?.image) {
        //   errors.image = "Theam is required";
        // }

        if (!value?.Title) {
            errors.Title = "Title is required";
        }
        // else if (!titleregx.test(value?.title)) {
        //   errors.Title = "Enter valid title";
        // }

        if (!value?.Description) {
            errors.Description = "Description is required";
        }
        // else if (!titleregx.test(value?.Description)) {
        //   errors.Description = "Enter valid Description";
        // }
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCreateNotes({
            ...CreateNotes,
            [name]: value,
        });
    };
    // api call for theam list
    const GetTheamlist = () => {
        setLoadingTheam(true)
        let data = "";
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${baseApi.baseurl}themenotes/themelist`,
            headers: {},
            data: data,
        };
        axios
            .request(config)
            .then((response) => {
                setTheamlist(response?.data?.data_all);
                handleTheamimage(response.data.data_all[0]);
                setLoadingTheam(false)
            })
            .catch((error) => {
                console.log(error);
                setLoadingTheam(false)
            });
    };

    useEffect(() => {
        GetTheamlist();
    }, []);

    // function to add teams in api
    const handleTheamimage = (val) => {
        setimage(`${baseApi.baseurl}` + `images/` + val?.backgroundimagename);
        setimage_id(val?._id);
    };

    //api call for create digital notes
    const CreateDigitalNotes = (e) => {
        e.preventDefault();
        setFormErrors(CreateNoteValidate(CreateNotes));
        setIsSubmit(true);
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            let data = {
                title: CreateNotes.Title,
                description: CreateNotes?.Description,
                theme: image_id,
                created_by: UserData?.userdata?._id
                ,
            };

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${baseApi.baseurl}notes/createnotes`,
                headers: {
                    Authorization: authToken,
                },
                data: data,
            };
            axios
                .request(config)
                .then((response) => {
                    if (response?.data?.message === "Successfully created...") {
                        navigate(`../${RouteName.Auth.MyActivityList}`);
                    } else {
                        // toast.error(response?.data?.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // toast.error(error?.data?.message);
                });
        }
        // else{
        //   toast.error("Enter Title and Description");
        // }
    }, [formErrors])


    return (
        <div className="app-container">
            <div className="side-menu-panel">
                <div className="menu-panel-logo">
                    <img className="logo" src={rtylifeLogo} alt="logo" />
                </div>
            </div>
            <div className="content">
                <Header
                    isBack={true}
                    header={"My Digital Notes"}
                    isShowIcons={false}
                ></Header>
                <div
                    className="dashboard-container"
                    style={{ maxHeight: "calc(100% - 13%)", padding: "14px" }}
                >
                    <div className="row">
                        <div className="col-12">
                            <p className="mt-2 mb-2 text-muted">
                                Journaling is one of the best way to monitor your feelings. Pour
                                them all out with our digital notes.
                            </p>
                        </div>

                        <div className="col-lg-9 col-md-12 mb-3 order-2 order-lg-1">

                            <div
                                className="dashboard-theme-notes mb-3"
                                style={{
                                    backgroundImage: image
                                        ? `url(${image})`
                                        : `url(https://images.unsplash.com/photo-1538645731800-4640c639bba7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YnJvd24lMjBiYWNrZ3JvdW5kfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60)`,
                                }}
                            >
                                <div className="row">
                                    <div className="col-lg-12 containers">
                                        <input
                                            type="text"
                                            className="transparent-input w-100 p-2 px-0"
                                            autoComplete="off"
                                            maxLength="40"
                                            name="Title"
                                            value={CreateNotes?.Title}
                                            placeholder="Enter Title Here"
                                            onChange={handleChange}
                                        ></input>
                                    </div>
                                </div>
                                <h3 className="secheading py-2">
                                    {CurrentDate},{CurrentTime}
                                </h3>
                                <Form className="position-relative">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <div className="mb-3">
                                            <textarea
                                                className="form-control"
                                                name="Description"
                                                value={CreateNotes?.Description}
                                                rows={18}
                                                placeholder="Start typing here..."
                                                onChange={handleChange}
                                                maxLength="1000"
                                            />
                                            <span className="text-danger">
                                                {formErrors.Title ? formErrors.Title : formErrors.Description}
                                            </span>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>

                            <div className="col-12 col-md-3 text-end float-end">
                                <Button
                                    type="submit"
                                    className="button_common notes-btn btn mt-2 btn-primary w-100"
                                    onClick={(e) => CreateDigitalNotes(e)}
                                >
                                    Save Note
                                </Button>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 mb-3 order-1 order-lg-2">

                            <div className="accordion select-theme" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed secheading px-0 py-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Select Note Theme
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body p-0">
                                            <div className="dd-menu mt-0">
                                                <p className="mt-0 mb-3 text-muted">
                                                    Choose a theme as per your feelings to pour your heart
                                                    into{" "}
                                                </p>
                                                <div className="wrapper-themes mt-3 mb-2 row">
                                                    {loadingTheam ?
                                                        <div className="alert alert-warning" role="alert">
                                                            Loading...
                                                        </div>
                                                        :
                                                        Theamlist &&
                                                        Theamlist.map((val) => {
                                                            return (
                                                                <div className="box col-6">
                                                                    <div className="card-body mb-2">
                                                                        <div className="playground-img-div mb-2 ">
                                                                            <img
                                                                                alt=""
                                                                                src={
                                                                                    `${baseApi.baseurl}` +
                                                                                    `images/` +
                                                                                    val?.backgroundimagename
                                                                                }
                                                                                className="img-fluid select-note-theme-img"
                                                                                onClick={() => handleTheamimage(val)}
                                                                            />
                                                                        </div>
                                                                        <div className="support-div-text">
                                                                            <h3 className="card-title">
                                                                                {val?.themeName}
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    );
};

export default CreateNotes;
