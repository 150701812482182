import React, { useEffect, useState } from "react";
import MobMenuBar from "../Common/MobMenuBar";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import Header from "../Common/Header";
import API from "../../Api/Api";
import { formatDateTime } from "../Common/Function";
import { socket } from "../../socket";

const Notifications = () => {
  const [isChecked, setIschecked] = useState(false);
  const [notification, setNotification] = useState([]);
  const [room] = useState("notification");
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   if (!socket || !room) return;

  //   const handleData = (data) => {
  //     setNotification((list) => [data, ...list]);
  //   };

  //   socket.on(room, handleData);

  //   return () => {
  //     socket.off(room, handleData); // Remove the listener with the same handler
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [socket, room]);

  useEffect(() => {
    API.NotificationApis.getAllnotificationApi()
      .then((response) => {
        if (response.data.status === 1) {
          setNotification(response.data.data_all);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isOpen]);

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} alt="logo" />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header header={"Notifications"} isBack={true}></Header>
        <MobMenuBar></MobMenuBar>
        <div className="dashboard-container">
          <div
            className="client-name-list"
            style={{ maxHeight: "calc(100%)" }}
            onClick={() => setIsOpen(!isOpen)}
          >
            {notification &&
              notification.map(
                ({ title, description, createdAt, type, receiver_id }, i) => {
                  return (
                    <>
                      {(type === "all" ||
                        receiver_id === UserData.userdata._id) && (
                        <div
                          className="client-name-card d-flex flex-row"
                          style={{ background: "#FFFFFF" }}
                        >
                          <div className="vert-div"></div>
                          <div className="content-par mx-2">
                            <p className="m-0 follow-up-header">{title}</p>
                            <p
                              className="follow-up-subhead"
                              style={{
                                textDecoration: isChecked
                                  ? "line-through"
                                  : "auto",
                              }}
                            >
                              {description}
                            </p>
                            <p
                              className="my-2"
                              style={{
                                position: "relative",
                              }}
                            >
                              {formatDateTime(createdAt)}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
              )}
            {/*
         <div
              className="client-name-card d-flex flex-row"
              style={{ background: "#FFFFFF" }}
            >
              <div className="vert-div"></div>
              <div className="content-par mx-2">
                <p className="m-0 follow-up-header">Create Activity</p>
                <p
                  className="follow-up-subhead"
                  style={{
                    textDecoration: isChecked ? "line-through" : "auto",
                  }}
                >
                  Here is an overview of your letest activities
                </p>
                <p
                  className="my-2"
                  style={{
                    position: "relative",
                  }}
                >
                  Today 2:00PM
                  {isChecked ? (
                    <>
                      <span>
                        <label className="check_container">
                          <input type="checkbox" checked={true} />
                          <span
                            className="checkmark"
                            style={{ top: "17px" }}
                          ></span>
                        </label>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>

            <div
              onClick={() => setIschecked(!isChecked)}
              className="client-name-card d-flex flex-row"
              style={{ background: "#FFFFFF" }}
            >
              <div className="vert-div"></div>
              <div className="content-par mx-2">
                <p className="m-0 follow-up-header">
                  3 people joined your 'Fight Anxiety' group
                </p>
                <p
                  className="follow-up-subhead"
                  style={{
                    textDecoration: isChecked ? "line-through" : "auto",
                  }}
                >
                  Congratulations! Your support group is here
                </p>
                <p
                  className="my-2"
                  style={{
                    position: "relative",
                  }}
                >
                  Today 2:00PM
                  {isChecked ? (
                    <>
                      <span>
                        <label className="check_container">
                          <input type="checkbox" checked={true} />
                          <span
                            className="checkmark"
                            style={{ top: "17px" }}
                          ></span>
                        </label>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>

            <div
              onClick={() => setIschecked(!isChecked)}
              className="client-name-card d-flex flex-row"
              style={{ background: "#FFFFFF" }}
            >
              <div className="vert-div"></div>
              <div className="content-par mx-2">
                <p className="m-0 follow-up-header">Create Activity</p>
                <p
                  className="follow-up-subhead"
                  style={{
                    textDecoration: isChecked ? "line-through" : "auto",
                  }}
                >
                  Here is an overview of your letest activities
                </p>
                <p
                  className="my-2"
                  style={{
                    position: "relative",
                  }}
                >
                  Today 2:00PM
                  {isChecked ? (
                    <>
                      <span>
                        <label className="check_container">
                          <input type="checkbox" checked={true} />
                          <span
                            className="checkmark"
                            style={{ top: "17px" }}
                          ></span>
                        </label>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>

            <div
              onClick={() => setIschecked(!isChecked)}
              className="client-name-card d-flex flex-row"
              style={{ background: "#FFFFFF" }}
            >
              <div className="vert-div"></div>
              <div className="content-par mx-2">
                <p className="m-0 follow-up-header">Create Activity</p>
                <p
                  className="follow-up-subhead"
                  style={{
                    textDecoration: isChecked ? "line-through" : "auto",
                  }}
                >
                  Here is an overview of your letest activities
                </p>
                <p
                  className="my-2"
                  style={{
                    position: "relative",
                  }}
                >
                  Today 2:00PM
                  {isChecked ? (
                    <>
                      <span>
                        <label className="check_container">
                          <input type="checkbox" checked={true} />
                          <span
                            className="checkmark"
                            style={{ top: "17px" }}
                          ></span>
                        </label>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>
        */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
