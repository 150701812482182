import { useEffect, useState } from 'react';
import Lazy from '../src/Route/Lazy';
import nointernetImg from '../src/assets/images/icons/nointernet.png'
import '../src/assets/css/style.css'



function App() {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    function handleOnline() {
      setOnline(true);
    }

    function handleOffline() {
      setOnline(false);
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div className="App">
      {isOnline ?
       <Lazy/>

        : <div className="home-page">
          <div className="container-fluid main-container">
            <div className="row library-row center-me padding-top-40-percent">
              
              <div className="col-12 center-me">
              <div className="errorimagediv center-me">
                <img src={nointernetImg} alt="nointernet" className="errorimage" />
              </div>
                <h1 className="text-white text-center heading-error">
                  Internet Connection Error
                </h1>
                <p className="mb-4 text-white">
                  Please check your connection and try again.
                </p>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  );
}

export default App;
