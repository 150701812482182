import React, { useState } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from '../Common/Header';
import SideBarMenu from '../Common/SideBarMenu';
import userIm from '../../assets/images/icons/Mask Group 8.png';
import callImg from '../../assets/images/icons/Path 430.png';
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import Modal from "react-modal";
import sessionImg from '../../assets/images/icons/undraw_completing_re_i7ap (1).png';
import plusIcon from '../../assets/images/icons/Icon feather-plus.png';

const JoinSession = () => {
    const [modalIsOpen, setModel] = useState(false);
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            height: "500px",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            background: "#FFF",
            borderRadius: "20px",
            paddingTop: "70px",
            width: "90%",
            maxWidth: "500px",
            boxShadow: 'grey 1px 3px 7px'
        },
    };

    function openModal() {
        setModel(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    function closeModal() {
        setModel(false);
    }

    return (
        <div className="app-container">
            <div className="side-menu-panel">
                <div className="menu-panel-logo">
                    <img className="logo" src={rtylifeLogo} />
                </div>
                <div>
                    <SideBarMenu></SideBarMenu>
                </div>
            </div>
            <div className="content" >
                <Header isBack={true} header={'Select Clients'} isShowIcons={false}></Header>
                <div className="dashboard-container" style={{ maxHeight: 'calc(100% - 20%)' }}>
                    <div className="calling-wind">
                        <img className="mt-3" src={userIm} style={{ height: '122px' }} />
                        <label className="call-nm mt-3">Fight Anxiety</label>
                        <label className="call-nm mt-3" style={{ fontWeight: '400' }}>calling...</label>
                        <button className="call-btn mt-4" onClick={() => openModal()}>
                            <img src={callImg} style={{ height: '30px' }} />
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p className="p-header">
                    Summary
                    <button onClick={closeModal} className="close-btn ">
                        <img src={closeBtn} />{" "}
                    </button>
                </p>
                <div className="d-flex justify-content-center flex-column align-items-center">
                    <img style={{height: '160px', width: '136px'}} src={sessionImg} />
                    <button className="add-session-btns mt-4" >
                        <img src={plusIcon} />
                    </button>
                    <p className="let-lbl mt-3" >Let's Upload your session summary</p>
                </div>
            </Modal>
        </div>
    )
}

export default JoinSession;