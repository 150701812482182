import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import Header from "../Common/Header";
import rightArrow from "../../assets/images/icons/Icon ionic-ios-arrow-down.png";
import Modal from "react-modal";
import closeBtn from "../../assets/images/icons/Icon material-close.png";
import AdminRoute from "../../Route/RouteDetails";
import userIcon from "../../assets/images/icons/Icon awesome-user-circle.png";
import searchIcon from "../../assets/images/icons/Icon ionic-md-search.png";
import API from "../../Api/Api";
import { toast } from "react-toastify";

const SelectReferral2 = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height: "500px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#FFF",
      borderRadius: "20px",
      paddingTop: "70px",
      width: "90%",
      maxWidth: "500px",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  // ==============================

  function openReasonModal() {
    setmodalReasonIsOpen(true);
  }

  function afterReasonOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeReasonModal() {
    setmodalReasonIsOpen(false);
  }
  // =============================

  const navigate = useNavigate();
  const ClentId = localStorage.getItem("ClientRefId");
  const userData = JSON.parse(localStorage.getItem("cmsUserData"));
  const ClientName = localStorage.getItem("ClientRefName");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalReasonIsOpen, setmodalReasonIsOpen] = React.useState(false);
  const [TherapistList, setTherapistList] = useState([]);
  const [TherapistData, setTherapistData] = useState({});

  const [TherapistIdRef, setTherapistIdRef] = useState("");
  const [isReferSubmit, setIsReferSubmit] = useState(false);
  const [ReasonError, setReasonError] = useState("");

  const [isSubmit, setisSubmit] = useState(false);

  useEffect(() => {
    try {
      API.Group.TherapistListing({}).then((response) => {
        console.log(response?.data?.data?.dataorg);
        setTherapistList(response?.data?.data?.dataorg);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleSelectTherapist = (e, value) => {
    console.log(value?.fullname);
    console.log({
      ID: value?._id,
      Mobile: value?.phone,
      Email: value?.email,
      Name: value?.fullname,
    });
    setTherapistData({
      ID: value?._id,
      Mobile: value?.phone,
      Email: value?.email,
      Name: value?.fullname,
    });
  };

  const handleChangeRefferal = (e) => {
    console.log(e.target.value);
    setTherapistIdRef(e.target.value);
  };

  useEffect(() => {
    if (isReferSubmit) {
      closeReasonModal();
      setIsReferSubmit(false);
      openModal();
    }
  }, [isReferSubmit]);

  const handleSubmitReason = (e) => {
    e.preventDefault();
    if (!TherapistIdRef) {
      setReasonError("Reason is Required!");
    } else {
      setIsReferSubmit(true);
      setReasonError("");
    }
  };

  useEffect(() => {
    if (isSubmit) {
      // console.log({
      //   client_id: ClentId,
      //   theropist_id: TherapistData?.ID,
      //   created_by: userData?.userdata?._id,
      //   Reason: TherapistIdRef,
      //   email: TherapistData?.Email,
      //   phone: TherapistData?.Mobile,
      // });

      try {
        API.Refer.CreateRefer({
          data: {
            client_id: ClentId,
            theropist_id: TherapistData?.ID,
            created_by: userData?.userdata?._id,
            Reason: TherapistIdRef,
            email: TherapistData?.Email,
            phone: TherapistData?.Mobile,
          },
        }).then((response) => {
          console.log(response?.data);
          if (response?.data?.status === 1) {
            closeReasonModal();
            openModal();
            localStorage.removeItem("ClientRefName");
            localStorage.removeItem("ClientRefId");
            toast.success("Sucessfully refer");
            navigate(`../${AdminRoute?.Auth?.MyReferrel}`);
          }
        });
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    }
  }, [isSubmit]);

  const handleSubmitRsn = (e) => {
    e.preventDefault();
    setisSubmit(true);
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
      </div>
      <div className="content">
        <Header
          isBack={true}
          header={"Select Therapist"}
          isShowIcons={false}
        ></Header>
        <div className="d-flex justify-content-end">
          <button
            className="m-2 px-2"
            style={{ height: "30px", borderRadius: "20px" }}
            // onClick={() => openModal()}
            onClick={() => openReasonModal()}
          >
            Next
          </button>
        </div>
        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 20%)" }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ position: "relative" }}
          >
            <span>
              <img src={searchIcon} className="search_ref_icon" />
            </span>
            <input placeholder="Search Therapist" className="search_ref" />
          </div>
          <div
            className="client-name-list mt-2"
            style={{ maxHeight: "calc(100% - 10%)" }}
          >
            {!TherapistList?.length ? (
              <h3>Data Not Found</h3>
            ) : (
              TherapistList?.length &&
              TherapistList?.map((val, i) => {
                return (
                  <div className="client-item" key={i}>
                    <p>
                      <img
                        className="mx-2"
                        style={{ height: "26px" }}
                        src={userIcon}
                      />
                      {val?.fullname}
                      <span>
                        <label className="check_container">
                          <input
                            t
                            name="Client"
                            type="radio"
                            value={val?._id}
                            onChange={(e) => handleSelectTherapist(e, val)}
                          />
                          <span
                            className="checkmark"
                            style={{ top: "-18px" }}
                          ></span>
                        </label>
                      </span>
                    </p>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button
          onClick={closeModal}
          className="close-btn "
          style={{ top: "7%", zIndex: "100" }}
        >
          <img src={closeBtn} />{" "}
        </button>
        <p className="pop-up-head-containter">Referrals</p>
        <p className="mt-2" style={{ fontSize: "14px" }}>
          Client: {ClientName}
        </p>
        <p className="mt-2" style={{ fontSize: "14px" }}>
          Therapist: {TherapistData?.Name}
        </p>

        <p className="my-4" style={{ fontSize: "14px" }}>
          Referring to:
        </p>
        <p className="my-4" style={{ fontSize: "14px" }}>
          Email Id
        </p>
        <input
          type="text"
          placeholder="Contact No."
          className="mb-3"
          name="EmailId"
          defaultValue={TherapistData?.Email}
          style={{ background: "#EFF2F2" }}
          readOnly
        />
        <p className="my-3" style={{ fontSize: "14px" }}>
          Contact No.
        </p>
        <input
          type="text"
          placeholder="Contact No."
          className="mb-3"
          name="Mobileno"
          defaultValue={TherapistData?.Mobile}
          style={{ background: "#EFF2F2" }}
          readOnly
        />
        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
          onClick={(e) => handleSubmitRsn(e)}
        >
          Done
        </button>
      </Modal>

      {/* ==== Reason for Refferal model ====== */}

      <Modal
        isOpen={modalReasonIsOpen}
        onAfterOpen={afterReasonOpenModal}
        onRequestClose={closeReasonModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeReasonModal} className="close-btn ">
          <img src={closeBtn} />{" "}
        </button>
        <p>Create Reason</p>
        <textarea
          rows="10"
          name="TherapistIdRef"
          className="form-input-text-area"
          placeholder="Reason for refferal"
          onChange={(e) => handleChangeRefferal(e)}
        ></textarea>
        <p className="text-danger">{ReasonError}</p>
        <button
          type="submit"
          className="button_common w-100 mt-3 mb-3 text-center"
          role="button"
          onClick={(e) => handleSubmitReason(e)}
        >
          Publish
        </button>
      </Modal>
    </div>
  );
};

export default SelectReferral2;
