import React, { useState, useEffect, useRef } from "react";
import SideBarMenu from '../../Common/SideBarMenu';
import MobMenuBar from "../../Common/MobMenuBar";
import Header from '../../Common/Header';
import rtylifeLogo from "../../../assets/images/Login/ryteLife-full.png";
import plusIcon from "../../../assets/images/icons/+.png";
import sendIcon from "../../../assets/images/icons/Icon feather-send.png";
import AdminRoute from "../../../Route/RouteDetails";
import commoan from "../../../Api/config";
import axios from "axios";
import API from "../../../Api/Api";
import { toast } from "react-toastify";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";



const AiAsistance = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [AIChat, setAIChat] = useState([]);
  const [AiInputChat, setAiInputChat] = useState("");
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [rows, setRows] = useState(1);
  const textareaRef = useRef(null);
  const [AIConversation, setAIConversation] = useState([]);
  const [isGenerating, setisGenerating] = useState("");
  const navigate = useNavigate();
  const [currentChatId, setcurrentChatId] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const megID = queryParams.get("id");
  const [useCase, setUseCase] = useState(4);
  const mobile = localStorage.getItem("InputType");
  const authToken = localStorage.getItem("AuthTokenClient");
  const [perviousChatList, setPerviousChatList] = useState([]);
	const [isRecording, setIsRecording] = useState(false);
 
  const convertBlobToBinaryData = (blob) => {
    console.log("blob : ", blob);
    console.log("Blob type:", blob.type);
    // debugger
    const reader = new FileReader();
    reader.onload = () => {
      const binaryData = reader.result;
      console.log("binaryData : ", binaryData);
      // debugger
      sendBinaryDataToBackend(binaryData, blob);
    };
    reader.readAsArrayBuffer(blob);
  };

  const sendBinaryDataToBackend = async (binaryData, blob) => {
    const data = new FormData();

    data.append("file", blob, "output.mp3");
    data.append("phone", UserData?.userdata?.phone)
		const encrypted = "51565400e6d1cb014ed02db50fa535b7"
    console.log(data);

    axios
      .post(`https://aitapi.rytlife.com:8001/transcribe_cms_connect`, data, {
        headers: {
          Authorization: authToken,
          'Content-Type': 'multipart/form-data',
					'x-api-key': encrypted
          // Remove "Content-Type" from here, Axios will set it automatically for FormData
        },
      })
      .then((response) => {
        console.log(response.data.transcription);
        handleSubmitChat(response.data.transcription)
        // setAllAiChat([...AllAiChat, response?.data?.output]);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error?.data?.message);
      });
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );


  const addAudioElement = (blob) => {
    convertBlobToBinaryData(blob);
   
  };

  const onRecord = (recState) => {
		setIsRecording(!recState);
	};

 

  useEffect(() => {
    getChatMsgID();
    chatlisthistory();
    if (megID) {
      getChatHistory(megID);
      setcurrentChatId(megID);
    }
    getChatHistory();
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (rows < 5) {
      setRows(Math.max(1, Math.floor(textarea.scrollHeight / 30)));
    }
    if (AiInputChat.length === 0) {
      setRows(1);
    }
  }, [AiInputChat]);

  const getChatMsgID = () => {
    API.Therapist.CreatMessageid({
      data: {
        phone: UserData?.userdata?.phone,
        name: UserData?.userdata?.fullname
      },
    })
      .then((response) => {
        let data = JSON.parse(response.data.data);
        setAIChat(JSON.parse(response.data.data));
        setcurrentChatId(data.message_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const chatlisthistory = () => {
    API.Therapist.Aichatlisthistory()
      .then((response) => {
        if (response.data.status === 1) {
          console.log("Previous chat", response.data);
          setPerviousChatList(response?.data?.userchat);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

 

 

 

  const getChatHistory = (megID) => {
    API.Therapist.ChatHistory({
      msgId: megID,
      name: UserData?.userdata?.fullname,
      phone: UserData?.userdata?.phone,
    })
      .then((response) => {
        // let data = JSON.parse(response.data.data)
        if (response?.data?.status === 1) {
          // setChatHistory(response?.data?.userchat)
          if (megID) {
            setAIConversation(response.data.userchat);
          } else {
            setChatHistory(response.data.userchat);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setAiInputChat(e.target.value);
  };

  const handleSubmitChat = (Msg) => {

		// if (AiInputChat.length) {
		setisGenerating(true);
		AIConversation?.length == 0
			? setAIConversation((prevArray) => [
				...prevArray,
				{
					content: AIChat?.output?.content,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "assistant",
				},
				{
					content: Msg,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "user",
				},
			])
			: setAIConversation((prevArray) => [
				...prevArray,
				{
					content: Msg,
					created_at: "2023-10-23T08:02:07.770Z",
					role: "user",
				},
			]);
		API.Therapist.Chatapi({
			data: {
				message_id: megID ? megID : currentChatId,
				phone: UserData.userdata.phone,
				name: UserData?.userdata?.fullname,
				message: Msg
			}
		}).then((response) => {
			if (response.data.status === 1) {
				let data = JSON.parse(response?.data?.data)
				setAiInputChat("");
				setAIConversation((prevArray) => [
					...prevArray,
					{
						content: data?.data?.message,
						created_at: new Date(),
						role: "assistant",
					},
				]);
				navigate(
					`../${AdminRoute?.Auth?.AiAsistance}?id=${data?.data?.message_id}`,
					{ replace: true }
				)
			}
			setisGenerating(false);
		}).catch((err) => {
			console.log(err)
		})
		// }

	};

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setRows(1);
      handleSubmitChat(AiInputChat);
      setAiInputChat("");
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        AiInputChat.substring(0, cursorPosition) +
        "\n" +
        AiInputChat.substring(cursorPosition);
      setAiInputChat(newInputValue);
      e.preventDefault();
    }
  };

  
  // function addAIMessage(msg) {
  //   // Call your function here
  //   // setAllAiChat([...AllAiChat, AiInputChat]);
  //   setisGenerating(true);
  //   try {
  //     let data = JSON.stringify({
  //       use_case_id: useCase,
  //       content: msg,
  //       message_id: AIChat?.message_id,
  //       phone: mobile,
  //     });

  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `${commoan.externalBaseUrl}input_chat`,
  //       headers: {
  //         Authorization: authToken,
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios.request(config).then((response) => {
  //       console.log(response?.data);
  //       if (response?.data?.result === "success") {
  //         setAiInputChat("");
  //         setisGenerating(false);

  //         // AllAiChat = [...AllAiChat, response?.data?.data?.message];
  //         // setAllAiChat([...AllAiChat, response?.data?.data?.message]);

  //         // =============================
  //         try {
  //           const qs = require("qs");
  //           let data = qs.stringify({
  //             message_id: AIChat?.message_id,
  //           });

  //           let config = {
  //             method: "post",
  //             maxBodyLength: Infinity,
  //             url: `${commoan.externalBaseUrl}getConversation`,
  //             headers: {
  //               authorization: authToken,
  //             },
  //             data: data,
  //           };

  //           axios.request(config).then((response) => {
  //             console.log(response?.data);
  //             setAIConversation(response?.data?.data?.message);
  //           });
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  
  
 

 

 

  return (
    <div className="app-container">
    <div className="side-menu-panel">
      <div className="menu-panel-logo">
        <img className="logo" src={rtylifeLogo} />
      </div>
      <div>
        <SideBarMenu></SideBarMenu>
      </div>
    </div>
    <div className="content">
      <Header></Header>
      <MobMenuBar></MobMenuBar>
      <div className="ai-chat-container text-white p-2 rounded-xl mx-0 mt-1 position-relative ai-therapist-wrap">
        <div className="therapist-switches-div flex rounded-pill justify-content-end bg-white/25 mt-2">
          {/* <Form className="float-left">
                  <label className="toggle">
                    <span className="toggle-label mr-2">Adam</span>
                    <input
                      className="toggle-checkbox"
                      type="checkbox"
                      checked={useCase == 4 ? false : true}
                      onChange={toggleUseCase}
                    />
                    <div className="toggle-switch" style={theme}></div>
                    <span className="toggle-label">Eve</span>
                  </label>
                </Form> */}
          <Dropdown className="dropdown mr-1">
            <Dropdown.Toggle variant="muted" id="drNewopdown-basic">
              <i className="fa fa-ellipsis-v arrow border-0"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="previouschat-dropdown chat-drop-menu text-white">
              <Dropdown.Item
                onClick={() => [
                  navigate(`../${AdminRoute?.Auth?.AiAsistance}`, {
                    replace: true,
                  }),
                  window.location.reload(true),
                ]}
              >
                <h6 className="mt-0 font-weight-medium dropdown-header text-white ">
                  New Chats{" "}
                </h6>
              </Dropdown.Item>
              <Dropdown.Item>
                <h6 className="mt-0 font-weight-medium dropdown-header text-white ">
                  Previous Chats{" "}
                </h6>
              </Dropdown.Item>

              {/* =================================== */}
              {/* {chatHistory?.map((item) => (
                <>
                  {item.role === "user"
                    ?
                    <Dropdown.Item
                      key={item?.message_id}
                      onClick={() => [navigate(`../${AdminRoute?.Auth?.AiAsistance}?id=${item?.message_id}`, { replace: true }), getChatHistory(item?.message_id)]}>
                      <div className="preview-item-content py-1 ">
                        <p className="font-weight-medium small-text my-0 text-white ">
                          {item?.content}
                        </p>
                      </div>
                    </Dropdown.Item >
                    : null
                  }
                </>
              ))} */}
              {/* ========================================== */}

              {perviousChatList?.map((item) => (
                <Dropdown.Item
                  key={item?.message_id}
                  onClick={() => [
                    navigate(
                      `../${AdminRoute?.Auth.AiAsistance}?id=${item?._id}`,
                      { replace: true }
                    ),
                    getChatHistory(item?._id),
                  ]}
                >
                  <div className="preview-item-content py-1 ">
                    <p className="font-weight-medium small-text my-0">
                      {item?.name}
                    </p>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="chats-mainai w-[98%] ml-[1%]">
          <div className="scroller max-h-[100%-73px] py-0 px-[10px]">
            <div className="scroller-content" id="scrollerContent">
              <div className="inner-content">
                <div
                  className="item recive-msg"
                  style={{ margin: "10px 0px" }}
                >
                  {AIConversation?.length
                    ? AIConversation?.map((val, index) => {
                        return (
                          <div className="" key={index}>
                            {val?.role === "assistant" ? (
                              <div className="recived-msg-row">
                                <div className="msg-card recived-msg-card">
                                  <div className="d-flex justify-content-end time-lbl ">
                                    {val?.content}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="sent-msg-row">
                                <div className=" msg-card sent-msg-card">
                                  <>
                                    <div className="mx-2 mb-1 user-lbl d-flex flex-wrap">
                                      {/* <img src={userChatIcon} className="mx-1 user-chat-img" /> */}
                                      {/* {UserData?.userdata?.fullname} */}
                                    </div>
                                    <div className="mx-2 my-0 msg-lbl">
                                      {val?.content}
                                    </div>
                                  </>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })
                    : AIChat?.output?.content && (
                        <div className="mb-3 w-100">
                          <div className="chatbox-rec float-start">
                            <div className="chatbox-rec chat-reciving-light">
                              <p className="my-0 reciving-text p-3 mt-2">
                                {AIChat?.output?.content}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                  {isGenerating ? (
                    <div className="m-5">
                      <div className="container_loader "></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="chat-input-box ai-asistance-chat send-box px-3 mb-3">
                <textarea
                  ref={textareaRef}
                  placeholder="Write a message..."
                  className="therapist-send-box-input text-white p-2 me-2"
                  rows={rows}
                  cols={50}
                  autoComplete="off"
                  value={isGenerating ? "" : AiInputChat}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handleKeyPress}
                  disabled={isGenerating}
                  style={{ resize: "none" }}
                />
                <button
                  className="send-btn"
                  onClick={() => handleSubmitChat(AiInputChat)}
                >
                  <img src={sendIcon} alt="" />
                </button>
                <div className="audio-bttn mx-1 p-0">
                  <AudioRecorder
                    onRecordingComplete={(blob) => addAudioElement(blob)}
                    recorderControls={recorderControls}
                    downloadOnSavePress={false}
                    downloadFileExtension="mp3"
                    showVisualizer={true}
                    onClick={() => onRecord(isRecording)}
                  />
                  {/* <button onClick={recorderControls.stopRecording}>Stop</button> */}
                  {/* <br /> */}
                  {/* audio input code ends */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default AiAsistance;