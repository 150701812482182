import React, { useState, useEffect } from "react";
import { Pagination, Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";

const SelectTherapist = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3); // Change the number of items per page here
  const [searchText, setSearchText] = useState("");
  const [Therpist_List, setTherpist_List] = useState([]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [selectedTherpist, setSelectedTherpist] = useState(null);
  const [isSubmit, setIssubmit] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [loading, setLoading] = useState(false);

  // api call for therpist
  const getTherpistList = () => {
    setLoading(true);
    try {
      API.Auth.WellnessCoachlisting({}).then((response) => {
        console.log(response?.data?.data_all);
        setTherpist_List(response?.data?.data_all);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Filter data based on search text
  const filteredData = Therpist_List.filter(
    (val) =>
      val?.fullname?.toLowerCase().includes(searchText.toLowerCase()) ||
      val?.email?.toLowerCase().includes(searchText.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getTherpistList();
  }, [currentPage]);

  // submit selected therapist list
  const handleTherapist = () => {
    if (!isSubmit) {
      setIssubmit(true);
      API.Auth.TherpistAdd({
        data: {
          clientId: UserData?.userdata?._id,
          theropistId: selectedTherpist?._id,
        },
      })
        .then((response) => {
          if (response.data.status === 1) {
            setIssubmit(false);
            localStorage.setItem("TherapistID", selectedTherpist?._id);
            navigate(`../${AdminRoute?.Auth?.MyCalender}`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="main-container dashboard therapist">
      <div className="row d-flex justify-content-start inner p-3 px-3">
        <div className="col-md-12 px-0">
          <Navbar className="top-bar mb-5 active justify-content-between">
            <NavLink to={AdminRoute?.Common?.Therapist}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </NavLink>
            <Nav className="mr-auto">
              <Form.Group className="position-relative select-therapist-search me-2">
                <i className="fa fa-search position-absolute"></i>
                <Form.Control
                  type="text"
                  placeholder="Search therapist"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Form.Group>

              <span className="logo-div">
                <NavLink to="/">
                  <img src={rtylifeLogo} className="logo m-0" alt="logo" />{" "}
                </NavLink>
              </span>
            </Nav>
          </Navbar>
        </div>
        <div className="col-md-12 mb-4 px-0">
          <h2 className="heading mb-2 mt-4">
            Begin Your Mental Health Journey
          </h2>
          <h4 className="secheading mb-2">With Our Friendly Experts</h4>
          <p className=" text-muted">please select your therapist</p>
        </div>

        {loading ? (
          <figure>
            <div className="dot white"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </figure>
        ) : currentItems.length === 0 ? (
          <div className="alert alert-warning digital-notes-alert" role="alert">
            Data Not Found
          </div>
        ) : (
          currentItems &&
          currentItems.map((val) => (
            <div
              className="col-md-4 mb-4 therapist-doctor"
              key={val._id}
              onClick={() => setSelectedTherpist(val)}
            >
              <div
                className={
                  selectedTherpist?._id === val?._id
                    ? "inner-selected inner-div d-flex h-100 cursor-pointer"
                    : "inner-div d-flex h-100 cursor-pointer"
                }
              >
                <div className="img d-flex justify-content-center align-items-center">
                  <img
                    src={
                      val?.image
                        ? val.image
                        : `${process.env.REACT_APP_BASE_URL}images/profile.png`
                    }
                    alt="theripist img"
                  />
                </div>
                <div className="therapist-doctor-text-div cursor-pointer">
                  <h3 className="fw-bold">
                    {val?.fullname}
                  </h3>
                  <p className="mb-2">{val?.email}</p>
                </div>
              </div>
            </div>
          ))
        )}

        <div className="d-flex flex-column-reverse mt-4 px-0">
          {/*
          <p className="mx-5">
          Showing {currentPage} of {totalPages}
        </p>
        */}
          <p className="">Showing 1 of 3</p>
          <Pagination>
            <Pagination.First
              onClick={() => {
                setCurrentPage(1);
              }}
            />
            <Pagination.Prev
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            />
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            />
            <Pagination.Last
              onClick={() => {
                setCurrentPage(totalPages);
              }}
            />
          </Pagination>
        </div>

        <div className="col-md-12 d-flex justify-content-end align-items-center px-0">
          <NavLink to={`../${AdminRoute.Auth.Dashboard}`}>
            <span className="my-auto">
              Skip
            </span>
          </NavLink>
          <Button
            type="submit"
            className="button_common mx-2 px-5"
            onClick={() => handleTherapist()}
            disabled={!selectedTherpist}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectTherapist;
