import React from "react";
import Home from "../../assets/images/icons/HomeIconWhite.png";
import CLients from "../../assets/images/icons/mentalhealthart-01.png";
import Sessions from "../../assets/images/icons/SessionsIconWhite.png";
import Groups from "../../assets/images/icons/GroupsiconWhite.png";
import AiAsistance from "../../assets/images/icons/AiIconWhite.png";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import activityListIcon from "../../assets/images/icons/DigitalNotesIconWhite.png";

const MobMenuBar = () => {
  const navigate = useNavigate();
  return (
    <div className="mob-menu-container">
      <NavLink to={`/`}>
        <div className="mobile-menu-item">
          <img src={Home} />
          <div>Home</div>
        </div>
      </NavLink>

      <div
        className="mobile-menu-item"
        onClick={() => navigate(`../${AdminRoute?.Auth?.MyActivityList}`)}
      >
        <img src={activityListIcon} />
        <div>Digital Notes</div>
      </div>

      <div
        className="mobile-menu-item"
        onClick={() => navigate(`../${AdminRoute?.Auth?.Sessions}`)}
      >
        <img src={Sessions} />
        <div>Sessions</div>
      </div>
      <NavLink to={`/groups`}>
        <div className="mobile-menu-item">
          <img src={Groups} />
          <div>Groups</div>
        </div>
      </NavLink>

      <div
        className="mobile-menu-item"
        onClick={() => navigate(`../${AdminRoute?.Auth?.AiAsistance}`)}
      >
        <img src={AiAsistance} />
        <div>AI Asistance</div>
      </div>
    </div>
  );
};

export default MobMenuBar;
