import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import rtylifeLogo  from "../../assets/images/Login/ryteLife-full.png";
import AdminRoute from "../../Route/RouteDetails";

const Home = () => {
  const navigate = useNavigate()
  return (

    <div className="app-container">
      <div className="logo-container">
          <img className="logo" src={rtylifeLogo} />
      </div>
      <div className="login-container">
        <div className="register-therapist-div register-therapist">
        <div className="register-therapist-div pt-3 ps-2">
            <h1 className="heading mb-0">
              <span className="d-block">Reclaim control</span>{" "}
              <span>over your</span>
            </h1>
            <p className="subheading">
              <span className="d-block">Mental Health </span>
              <span>journey</span>
            </p>
            <p className="through-our-online-theropy">
              through our online therapy programs, guided by our compassionate
              team of psychologists.
            </p>

            <NavLink
              type="submit"
              className="button_common w-100 my-3 mb-3 text-center"
              role="button"
              to={ navigate(`../${AdminRoute?.Auth?.Login}`)}
            >
              Start Now
            </NavLink>
          </div>
          </div>
        </div>
    </div>
  );
};

export default Home;
