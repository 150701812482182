import React, { useState, useEffect } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import profileImg from "../../assets/images/icons/Icon awesome-user-circle.png";
import groupIcon from "../../assets/images/icons/Group 21730.png";
import groupIcon2 from "../../assets/images/icons/Group 21756.png";
import aiTherapist from "../../assets/images/dashboard/ai-therapist.jpg"
import groupImg from "../../assets/images/dashboard/groups.jpg"
import playgroundImg from "../../assets/images/dashboard/playground.jpg"
import AdminRoute from "../../Route/RouteDetails";
import { NavLink, useNavigate } from "react-router-dom";
import API from "../../Api/Api";

const NeedAnalysis = () => {
    const navigate = useNavigate();
    const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
    const [UpcommingList, setUpcommingList] = useState([]);
    const [ClientName, setClientName] = useState("");

    //api call for upcommming session

    const upcomingListfun = () => {
        try {
            API.Client.UpcomingListdashboard({
                UserId: UserData?.userdata?._id,
                isApproved: "1",
            }).then((response) => {
                if (response.data.status === 1) {
                    setUpcommingList(response?.data?.data_all[0]);
                    setClientName(
                        `${response?.data?.data_all[0]?.client[0]?.name} ${response?.data?.data_all[0]?.client[0]?.last_name}`
                    );
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        upcomingListfun();
    }, []);

    //   console.log("UpcommingList", UpcommingList?.client[0]?.name);

    return (
        <div className="app-container">
            <div className="side-menu-panel">
                <div className="menu-panel-logo">
                    <img className="logo" src={rtylifeLogo} />
                </div>
                <div>
                    <SideBarMenu></SideBarMenu>
                </div>
            </div>
            <div className="content">
                <Header
                    isBack={true}
                    header={"Need Analysis"}
                    isShowIcons={false}
                ></Header>
                <MobMenuBar></MobMenuBar>
                <div
                    className="dashboard-container analysis-form-v-height"
                    style={{ maxHeight: "calc(100% - 160px)" }}
                >

                    {/* ===========Wellness Tracker============ */}


                    <div className="row px-3 mt-3 need-analysis-form">

                        <div className="col-12 mb-2">
                            <div className="d-flex justify-content-between">
                                <p>Before proceeding, there will be a brief test with this set of questions.</p>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-4 mb-3">
                            <div className="dashboard-inner p-2 h-100">

                                <div className="dashboard-text-div h-100">
                                    <p className="px-3 pb-2">1. In a general, whicj statement describe you best?</p>

                                    <div className="analysis-form p-3 h-100">

                                        <p className='sessions-summery-text'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Absence of formal job orientation
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    job duties change without notice
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Unclear job expectaions
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Overley broad load defination leading to unrelated task
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Other
                                                </label>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-4 mb-3">
                            <div className="dashboard-inner p-2 h-100">

                            <div className="dashboard-text-div h-100">
                                    <p className="px-3 pb-2">1. In a general, whicj statement describe you best?</p>

                                    <div className="analysis-form p-3 h-100">

                                        <p className='sessions-summery-text'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Absence of formal job orientation
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    job duties change without notice
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Unclear job expectaions
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Overley broad load defination leading to unrelated task
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Other
                                                </label>
                                            </div>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-12 col-lg-4 mb-3">
                            <div className="dashboard-inner p-2 h-100">

                            <div className="dashboard-text-div h-100">
                                    <p className="px-3 pb-2">1. In a general, whicj statement describe you best?</p>

                                    <div className="analysis-form p-3 h-100">

                                        <p className='sessions-summery-text'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Absence of formal job orientation
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    job duties change without notice
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Unclear job expectaions
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Overley broad load defination leading to unrelated task
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Other
                                                </label>
                                            </div>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row px-3 mt-3 justify-content-end">
                        <div className="col-12 col-md-2">
                            <Button
                                className="button_common m-0 px-3 w-100"
                            >
                                Submit
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NeedAnalysis;
