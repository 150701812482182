import { io } from "socket.io-client";

// // "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_BASE_URL_SOCKET;

// export const socket = io(URL, {
//   path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
// });





// export const socket = io(URL, {
//   path: `/${process.env.WDS_SOCKET_HOST}/socket.io`,
// });

export const socket = io(URL, {
  path: `/socket.io`,
});
