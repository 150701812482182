import React, { useState, useEffect } from "react";
import moment from "moment";
import rtylifeLogo from "../../../assets/images/Login/ryteLife-full.png";
import API from "../../../Api/Api";
import SideBarMenu from "../../Common/SideBarMenu";
import MobMenuBar from "../../Common/MobMenuBar";
import Header from "../../Common/Header";
import GroupImg from "../../../assets/images/background/blue-bg.jpg";
import Button from "react-bootstrap/Button";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import SuccessPop from "../../Common/Modal/SuccessPop";
import { checkButtonRange } from "../../Common/Function";
import baseApi from "../../../Api/config";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const RegisterGroup = () => {
  const [FocusList, setFocusList] = useState([]);
  const [focussedLoading, setfocussedLoading] = useState(false);
  const [SupportList, setSupportList] = useState([]);
  const [supportLoading, setSupportLoading] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("cmsUserData"));
  const [showPopup, setShowPopUp] = useState(false);

  const FocusGroupListFun = () => {
    setfocussedLoading(true);
    try {
      API.Group.GroupList({
        Grouptype: "focused",
        data: {
          type: "focused",
        },
      }).then((response) => {
        setfocussedLoading(false);
        setFocusList(response?.data?.data_all);
      });
    } catch (error) {
      console.log(error);
      setfocussedLoading(false);
    }
  };

  const SupportGroupListFun = () => {
    setSupportLoading(true);
    try {
      API.Group.GroupList({
        Grouptype: "support",
        data: {
          type: "support",
        },
      }).then((response) => {
        setSupportLoading(false);
        setSupportList(response?.data?.data_all);
      });
    } catch (error) {
      setSupportLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    FocusGroupListFun();
    SupportGroupListFun();
  }, []);

  const handleRegister = (group_id, info) => {
    // setLoading(true)
    API.Group.JoinGroups({
      data: {
        userId: UserData?.userdata?._id,
        groupId: group_id,
      },
    })
      .then((response) => {
        if (response.data.status === 1) {
          setShowPopUp(true);
          setTimeout(() => {
            setShowPopUp(false);
          }, 2000);
          FocusGroupListFun();
          SupportGroupListFun();
        }
        if (response.data.status === 0) {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error);
      });
  };

  console.log(UserData);

  return (
    <>
      {showPopup ? (
        <SuccessPop text="Successfully Joined Group" />
      ) : (
        <div className="app-container">
          <MobMenuBar></MobMenuBar>
          <div className="side-menu-panel">
            <div className="menu-panel-logo">
              <img className="logo" src={rtylifeLogo} alt="logo" />
            </div>
            <div>
              <SideBarMenu></SideBarMenu>
            </div>
          </div>
          <div className="content">
            <Header isBack={true}></Header>
            <div className="group-container px-2 register-group">
              <div className="col-12">
                <h2 className="heading mb-2">
                  Find strength in our sup port system
                </h2>
                <h4 className="secheading mb-2">Join Our Groups</h4>
              </div>

              <div className="col-12 focussed">
                <p className="mb-1 focussed-heading mt-4 mb-3">
                  Focussed Groups
                </p>
                <Carousel responsive={responsive}>
                  {focussedLoading ? (
                    <div className="loader_orange"></div>
                  ) : FocusList?.length === 0 ? (
                    <div
                      className="alert alert-warning digital-notes-alert"
                      role="alert"
                    >
                      Data Not Found
                    </div>
                  ) : (
                    FocusList &&
                    FocusList.map((slide) => {
                      return (
                        <div className="row">
                          <div className="card-body mx-4" key={slide?._id}>
                            <div className="focussed-div d-flex justify-content-center align-items-center overflow-hidden">
                              <span className="focussed-text">Focussed</span>
                              <img
                                className="d-block w-100"
                                src={
                                  slide.logo
                                    ? `${baseApi.baseurl}/images/${slide?.logo}`
                                    : `${process.env.REACT_APP_BASE_URL}images/group-default.jpg`
                                }
                                alt={slide.caption}
                              />
                            </div>
                            <div className="focussed-div-text">
                              <h3 className="card-title">{slide?.title}</h3>
                              <p className="card-text mb-0 fw-bold">
                                {slide?.name}
                              </p>
                              <p className="card-text my-2">
                                Members: {slide?.members}
                              </p>
                              <p className="startDate mt-1 mb-1">
                                Start Date:{" "}
                                {moment(slide?.startdate).format("DD/MM/YYYY")}
                              </p>
                              <p className="endDate mb-1">
                                End Date :{" "}
                                {moment(slide?.enddate).format("DD/MM/YYYY")}
                              </p>
                              <Button
                                className="transparent-btn mt-2 mb-2"
                                onClick={() =>
                                  handleRegister(slide?._id, "Focussed")
                                }
                                disabled={
                                  !checkButtonRange(
                                    slide?.startdate,
                                    slide?.enddate
                                  ) ||
                                  slide?.usergroup?.length ||
                                  UserData?.userdata?.Companyname !==
                                    slide?.org_name
                                }
                              >
                                Register
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </Carousel>
              </div>

              <div className="col-12 focussed">
                <p className="mt-4 mb-1 focussed-heading mb-3">
                  Support Groups
                </p>
                {/* Static Support group  */}
                <Carousel responsive={responsive}>
                  {supportLoading ? (
                    <div className="loader_orange"></div>
                  ) : SupportList?.length === 0 ? (
                    <div
                      className="alert alert-warning digital-notes-alert"
                      role="alert"
                    >
                      Data Not Found
                    </div>
                  ) : (
                    SupportList &&
                    SupportList.map((slide) => {
                      return (
                        <div className="row" key="">
                          <div className="card-body mx-4">
                            <div className="support-div d-flex justify-content-center align-items-center overflow-hidden">
                              <span className="support-text">Support</span>
                              <img
                                className="d-block w-100"
                                src={
                                  slide.logo
                                    ? `${baseApi.baseurl}/images/${slide?.logo}`
                                    : `${process.env.REACT_APP_BASE_URL}images/group-default.jpg`
                                }
                                alt={slide?.caption}
                              />
                            </div>

                            <div className="support-div-text">
                              <h3 className="card-title">{slide?.title}</h3>
                              <p className="card-text mb-0 fw-bold">
                                {slide?.name}
                              </p>

                              <Button
                                className="transparent-btn mt-2 mb-2"
                                onClick={() =>
                                  handleRegister(slide?._id, "Support")
                                }
                                disabled={
                                  slide?.usergroup?.length ||
                                  UserData?.userdata?.Companyname !==
                                    slide?.org_name
                                }
                              >
                                Register
                              </Button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterGroup;
