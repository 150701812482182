const RouteName = {
  Auth: {
    Home: "/Home",
    Login: "/login",
    Registration1: "/register1",
    Registration2: "/register2",
    VerifyOtp: '/VerifyOtp',
    verifyOtpMail: '/verifyotpmail',
    OurFeatures: '/our-features',
    NeedAnalysisForm: '/need-analysis-form',
    NeedAnalysis: '/need-analysis',
    SelectTherapist: '/select-therapist',
    UpdatesFromTherapist: '/updates-from-therapist',
    Dashboard: '/',
    Profile: '/profile',
    ProfileEdit: '/profile-edit',
    PersonalityTests: '/personality-tests',
    WellBeingTest: '/well-being-test',
    MyCalender: '/my-calender',
    MyActivityList: '/my-activity-list',
    ChatBox: '/ChatBox',
    Groups: '/groups',
    MembersSelection: '/members-selection',
    ClientList: '/client-list',
    ClientDetails: '/client-details',
    ClientViewDet: '/client-view',
    Sessions: '/sessions',
    SessionDetails: '/session-details',
    Settings: '/settings',
    MyReferrel: '/my-referrel',
    SelectReferral1: '/select-referral1',
    SelectReferral2: '/select-referral2',
    DigitalNotes: '/digital-notes',
    DigitalNotesDetails: '/digital-notes-details',
    CreateNotes: '/create-notes',
    AiAsistance: '/ai-asistance',
    AddEditSessionDetails: '/add-edit-session-det',
    Playground: '/playground',
    JoinSession: '/join-session',
    FollowUpList: '/follow-ups',
    Notifications: '/notifications',
    LeaderShipForm:'/leader_ship_form',
    RegisterGroup: '/register-group'
  },
  Video: {
    VideoCall: '/VideoCall',
  },
  PageNotFound: "*",
};

export default RouteName;
