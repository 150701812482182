import React, { useEffect, useState } from "react";
import rtylifeLogo from "../../assets/images/Login/ryteLife-full.png";
import SideBarMenu from "../Common/SideBarMenu";
import MobMenuBar from "../Common/MobMenuBar";
import Header from "../Common/Header";
import profileImg from "../../assets/images/icons/Icon awesome-user-circle.png";
import groupIcon from "../../assets/images/icons/Group 21730.png";
import groupIcon2 from "../../assets/images/icons/Group 21756.png";
import API from "../../Api/Api";
import baseApi from "../../Api/config";
import moment from "moment";

const Playground = () => {
  const [Mental_HealthList, setMental_HealthList] = useState([]);
  const [Videos, setVideos] = useState([]);
  const [Assessment_List, setAssessment_List] = useState([]);
  const [loadingMental_HealthList, setLoadingMental_HealthList] =
    useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loadingAssessment_Lists, setLoadingAssessment_List] = useState(false);

  //get all data
  useEffect(() => {
    getMntal_HealthList();
    get_VideoList();
    get_AssesmentList();
  }, []);

  //api call for Mental Health  list
  const getMntal_HealthList = () => {
    setLoadingMental_HealthList(true);
    try {
      API.Plaground.Mental_HealthAPI({
        health: "health",
      }).then((response) => {
        if (response?.data.status === 1) {
          console.log(response?.data?.data?.data_all);
          setMental_HealthList(response?.data?.data_all);
        }
        setLoadingMental_HealthList(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingMental_HealthList(false);
    }
  };

  //api call for videos

  //api call for Mental Health  list
  const get_VideoList = () => {
    setLoadingVideos(true);
    try {
      API.Plaground.Videos_API({
        kind: "kind",
      }).then((response) => {
        if (response?.data.status === 1) {
          setVideos(response?.data?.data_all);
        }
        setLoadingVideos(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingVideos(false);
    }
  };

  //api  call for self assessment

  const get_AssesmentList = () => {
    setLoadingAssessment_List(true);
    try {
      API.Plaground.SelfAsses_API({
        selfAsses: "selfAsses",
      }).then((response) => {
        if (response?.data.status === 1) {
          setAssessment_List(response?.data?.data_all);
        }
        setLoadingAssessment_List(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingAssessment_List(false);
    }
  };

  const get_video = (e, video) => {
    console.log(video);
    const response = {
      file_path: video,
    };
    window.open(response.file_path);
  };

  return (
    <div className="app-container">
      <div className="side-menu-panel">
        <div className="menu-panel-logo">
          <img className="logo" src={rtylifeLogo} />
        </div>
        <div>
          <SideBarMenu></SideBarMenu>
        </div>
      </div>
      <div className="content">
        <Header isBack={true} header={"Playground"}></Header>
        <MobMenuBar></MobMenuBar>
        <div className="sub-head px-3 d-flex">
          <div>
            <p className="sub-lbl mt-3 mb-2">
              Here are list of your upcoming post and past sessions
            </p>
          </div>
        </div>
        <div
          className="dashboard-container"
          style={{ maxHeight: "calc(100% - 196px)" }}
        >
          <div className="sub-head mt-1 px-3">
            <p className="sub-heading">Mental Health Resource</p>
          </div>
          <div className="common-list-hor">
            {loadingMental_HealthList ? (
              <div className="container_loader"></div>
            ) : Mental_HealthList.length === 0 ? (
              <span>Data not found</span>
            ) : (
              Mental_HealthList?.map((val) => (
                <div
                  className="group-card"
                  key={val._id}
                  style={{ borderRadius: "14px" }}
                >
                  <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                    <img
                      style={{
                        objectFit: "cover",
                        maxHeight: "95px",
                        width: "143px",
                      }}
                      //   src={groupIcon2}
                      src={
                        `${baseApi.baseurl}` +
                        `images/` +
                        val?.backgroundimagename
                      }
                      alt={
                        `${baseApi.baseurl}` +
                        `images/` +
                        val?.backgroundimagename
                      }
                    />
                  </div>

                  <p className="mb-0 mt-2 group-card-lbl">{val?.header}</p>
                  <p className="mb-0">Author: {val?.header}</p>
                  {/* <p className="mb-0" style={{ fontSize: "13px" }}>
                    Jitesh Naidu
                  </p> */}
                </div>
              ))
            )}
          </div>

          <div className="sub-head mt-3 px-3">
            <p className="sub-heading">Videos</p>
          </div>
          <div className="common-list-hor">
            {loadingVideos ? (
              <div className="container_loader"></div>
            ) : Videos.length === 0 ? (
              <span>Data not found</span>
            ) : (
              Videos?.map((val) => (
                <div
                  className="group-card"
                  key={val._id}
                  style={{ borderRadius: "14px" }}
                >
                  <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                    <img
                      style={{
                        objectFit: "cover",
                        maxHeight: "95px",
                        width: "143px",
                      }}
                      // src={groupIcon2}
                      src={
                        `${baseApi.baseurl}` +
                        `images/` +
                        val?.backgroundimagename
                      }
                      alt={
                        `${baseApi.baseurl}` +
                        `images/` +
                        val?.backgroundimagename
                      }
                    />
                  </div>

                  <p className="mb-0 mt-2 group-card-lbl">Break The Silence</p>
                  <p className="mb-0">Author:</p>
                  <p className="mb-0" style={{ fontSize: "13px" }}>
                    Jitesh Naidu
                  </p>
                </div>
              ))
            )}
          </div>

          <div className="sub-head mt-3 px-3">
            <p className="sub-heading">Self Assessment Test</p>
          </div>
          <div className="common-list-hor">
            {loadingAssessment_Lists ? (
              <div className="container_loader"></div>
            ) : Assessment_List.length === 0 ? (
              <span>Data not found</span>
            ) : (
              Assessment_List?.map((val) => (
                <div className="group-card" style={{ borderRadius: "14px" }}>
                  <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                    <img
                      style={{
                        objectFit: "cover",
                        maxHeight: "95px",
                        width: "143px",
                      }}
                      // src={groupIcon2}
                      src={
                        `${baseApi.baseurl}` +
                        `images/` +
                        val?.backgroundimagename
                      }
                      alt={
                        `${baseApi.baseurl}` +
                        `images/` +
                        val?.backgroundimagename
                      }
                    />
                  </div>

                  <p className="mb-0 mt-2 group-card-lbl">{val?.header}</p>
                  <p className="mb-0">Author: {val?.header}</p>
                  {/* <p className="mb-0" style={{ fontSize: "13px" }}>
                    Jitesh Naidu
                  </p> */}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playground;
