import axios from "axios";
import baseApi from "./config";

const BaseApi = (props) => {
  // Admin Object
  const adminObject = JSON.parse(localStorage.getItem("cmsUserData"));

  // Request Configuration
  const configuration = {
    method: props.method,
    url: props.url,
    baseURL: baseApi.baseurl,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    tokenRequired: props.tokenRequired,
  };
  // Request Data
  if (props.method === "POST") {
    configuration.data = props.data;
  }

  if (props.method === "PUT") {
    configuration.params = props.params;
    configuration.data = props.data;
  }

  // Request Params
  if (props.method === "GET") {
    configuration.params = props.params;
  }

  // Request Header Authorization
  if (props.tokenRequired) {
    if (adminObject) {
      configuration.headers.Authorization = adminObject && `${adminObject.token}`;
      
    }
  }

  // Return Request
  return axios(configuration);
};

export default BaseApi;
